import humps from "humps";

import { apiPhotoURL, apiURL, apiURLv2 } from "config";
import rollbar from "rollbar";
import { trimmedPhoneWithPlus } from "utils/formattedPhone";
import { loadImageSrc } from "utils/imageLoader";

/*
  Запрос в API
*/
export const backReq = async (action, data, callback) => {
	let tz = "";
	try {
		tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
	} catch (error) {
		console.log("Intl not supported in your browser");
	}
	const validData = _prepareValidDataForBackEnd(data);
	const body = { tz, ...validData };

	const sessionToken = localStorage.getItem("sessionToken");
	try {
		const response = await fetch(`${apiURL}${action}/`, {
			method: "POST",
			body: JSON.stringify(camel2under(body)),
			headers: { Authorization: sessionToken, "Client-Type": "web" },
		});
		const data = await response.json();
		if (data.status === "success" || data.status === "error") {
			if (data.session_token) {
				localStorage.setItem("sessionToken", data.session_token);
				// side effects
				if (typeof callback === "function") {
					callback();
				}
				// end of side effects
			}
			return _dataCamelizer(action, data);
		}
	} catch (e) {
		if (rollbar) {
			rollbar.error(`
                ${e.message}
                ${e.stack}
              `);
		}
		console.error(`
                ${e.message}
                ${e.stack}
              `);
	}
};

/*
  Запрос в API v2
*/
export const backReqV2 = async (action, data, callback) => {
	let tz = "";
	try {
		tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
	} catch (error) {
		console.log("Intl not supported in your browser");
	}
	const validData = _prepareValidDataForBackEnd(data);
	const body = { tz, ...validData };

	const sessionToken = localStorage.getItem("sessionToken");
	try {
		const response = await fetch(`${apiURLv2}${action}/`, {
			method: "POST",
			body: JSON.stringify(camel2under(body)),
			headers: { Authorization: sessionToken, "Client-Type": "web" },
		});
		const data = await response.json();
		if (data.status === "success" || data.status === "error") {
			if (data.session_token) {
				localStorage.setItem("sessionToken", data.session_token);
				// side effects
				if (typeof callback === "function") {
					callback();
				}
				// end of side effects
			}
			return _dataCamelizer(action, data);
		}
	} catch (e) {
		if (rollbar) {
			rollbar.error(`
                ${e.message}
                ${e.stack}
              `);
		}
		console.error(`
                ${e.message}
                ${e.stack}
              `);
	}
};

export const getIsoDateStringWithTimezone = () => {
	const currentDate = new Date();
	const offsetInMinutes = currentDate.getTimezoneOffset();
	const localDate = new Date(currentDate.getTime() - offsetInMinutes * 60000);
	const isoDateStringWithTimezone = localDate.toISOString().slice(0, -1);

	return isoDateStringWithTimezone;
};

function _dataCamelizer(action, data) {
	if (action === "get_partner_status") return humps.camelizeKeys(data);
	if (action === "get_partner_history") return humps.camelizeKeys(data);
	if (action === "get_news") return humps.camelizeKeys(data);
	if (action === "get_news_details") return humps.camelizeKeys(data);
	if (action === "get_contacts") return humps.camelizeKeys(data);
	if (action === "loans_list") return humps.camelizeKeys(data);
	if (action === "loan") return humps.camelizeKeys(data);
	if (action === "get_schedule") return humps.camelizeKeys(data);
	// TODO: Move all response via humps, then remove under2camel
	return under2camel(data);
}

function _prepareValidDataForBackEnd(rawData) {
	if (!rawData) return;
	const validData = JSON.parse(
		JSON.stringify(rawData).replace(/"\s+|\s+"/g, '"'),
	);
	if (validData.phone && typeof validData.phone === "string")
		validData.phone = trimmedPhoneWithPlus(validData.phone);
	if (
		validData.additionalPhone &&
		typeof validData.additionalPhone === "string"
	)
		validData.additionalPhone = `+${validData.additionalPhone.replace(/\D/gi, "")}`;
	if (validData.cardNumber && typeof validData.cardNumber === "string")
		validData.cardNumber = validData.cardNumber.replace(/\s/g, "");
	if (validData.income && typeof validData.income === "string")
		validData.income = Number.parseInt(validData.income) || validData.income;
	if (
		validData.additionalIncome &&
		typeof validData.additionalIncome === "string"
	)
		validData.additionalIncome =
			Number.parseInt(validData.additionalIncome) || validData.additionalIncome;
	return validData;
}

/*
 высылка фото авто в бд
*/
export const sendPhotoToDatabase = async (formData) => {
	const sessionToken = localStorage.getItem("sessionToken");
	const headers = { Authorization: sessionToken, "Client-Type": "web" };
	formData.append("id", localStorage.getItem("loanId"));
	const requestOptions = {
		method: "POST",
		headers,
		body: formData,
		redirect: "follow",
	};
	return fetch(apiPhotoURL, requestOptions)
		.then((res) => res.json())
		.then((data) => data);
};

/*
  Преобразование ключей объекта из camelCase в under_score-формат
  @param {Object} data  // объект для выполнения преобразования
*/
export const camel2under = (data) => {
	let convertedData = {};

	// Посимвольное преобразование строки ключа
	const toUnderscore = (key) => {
		const convertedKeySymbolsArr = [];

		key.split("").forEach((symbol) => {
			if (symbol === symbol.toUpperCase()) {
				convertedKeySymbolsArr.push("_");
				convertedKeySymbolsArr.push(symbol.toLowerCase());
			} else {
				convertedKeySymbolsArr.push(symbol);
			}
		});

		return convertedKeySymbolsArr.join("");
	};

	// Если передана строка
	if (typeof data === "string") {
		convertedData = toUnderscore(data);
		return convertedData;
	}

	// Проход по всем ключам пришедшего объекта
	for (const key in data) {
		const value = data[key];

		if (
			typeof value === "object" &&
			!(value instanceof Array) &&
			value !== null
		) {
			convertedData[toUnderscore(key)] = camel2under(value);
		} else {
			convertedData[toUnderscore(key)] = value;
		}
	}

	return convertedData;
};

/*
  Преобразование ключей объекта из under_score в camelCase-формат
  @param {Object} data  // объект для выполнения преобразования
*/
export const under2camel = (data) => {
	let convertedData = {};

	// Посимвольное преобразование строки ключа
	const toCamel = (key) => {
		const convertedKeySymbolsArr = [];

		const splittedKeyArr = key.split("");

		splittedKeyArr.forEach((symbol, i) => {
			if (symbol === "_") return;

			if (i === 0) convertedKeySymbolsArr.push(symbol);

			if (splittedKeyArr[i - 1] === "_") {
				convertedKeySymbolsArr.push(symbol.toUpperCase());
			} else if (i > 0) {
				convertedKeySymbolsArr.push(symbol);
			}
		});

		return convertedKeySymbolsArr.join("");
	};

	// Если передана строка
	if (typeof data === "string") {
		convertedData = toCamel(data);
		return convertedData;
	}

	// Проход по всем ключам пришедшего объекта
	for (const _key in data) {
		const key = toCamel(_key);
		const value = data[_key];

		// Обрабатываемый ключ - объект
		if (
			typeof value === "object" &&
			!(value instanceof Array) &&
			value !== null
		) {
			convertedData[key] = under2camel(value);

			// Обрабатываемый ключ - массив
		} else if (
			typeof value === "object" &&
			value instanceof Array &&
			value !== null
		) {
			convertedData[key] = [];

			value.forEach((elem) => {
				if (typeof elem === "string") {
					convertedData[key].push(elem);
				} else {
					convertedData[key].push(under2camel(elem));
				}
			});

			// Остальные случаи
		} else {
			convertedData[key] = value;
		}
	}

	return convertedData;
};

/*
  Получение "чистого" телефона, без форматирующих знаков
  @param {String}  formattedPhone                  // строка с телефоном для форматирования
  @param {Object}  options                         // объект с опциями
  @param {String} options.format = 'withPlus'      // с плюсом в начале
                                   'withoutSeven'  // без семерки в начале
*/
export const getPlainPhone = (formattedPhone, options = {}) => {
	if (!formattedPhone) return "";
	const plainPhone = formattedPhone
		.split("")
		.filter((symbol) => Number.isInteger(+symbol) && !symbol.match(/\s/))
		.join("");

	if (options.format === "withPlus") return `+${plainPhone}`;
	if (options.format === "withoutSeven") return plainPhone.slice(1);
	return plainPhone;
};

/*
  Получение форматированного телефона в виде '+7 (xxx) xxx-xx-xx'
  @param {String} formattedPhone  // строка с телефоном для форматирования
*/
export const getFormattedPhone = (rawPhone) => {
	if (!rawPhone) return "";
	const formattedPhoneArr = [];

	// 0  -> '+' -> +
	// 1  -> '7' -> '7 ('
	// 2  -> '9' -> '9'
	// 3  -> '8' -> '8'
	// 4  -> '8' -> '8) '
	// 5  -> '1' -> '1'
	// 6  -> '0' -> '0'
	// 7  -> '8' -> '8-'
	// 8  -> '5' -> '5'
	// 9  -> '8' -> '8-'
	// 10 -> '5' -> '5'
	// 11 -> '8' -> '8'

	rawPhone.split("").map((symbol, i) => {
		switch (i) {
			case 1:
				formattedPhoneArr.push(`${symbol} (`);
				break;
			case 4:
				formattedPhoneArr.push(`${symbol}) `);
				break;
			case 7:
				formattedPhoneArr.push(`${symbol}-`);
				break;
			case 9:
				formattedPhoneArr.push(`${symbol}-`);
				break;
			default:
				formattedPhoneArr.push(symbol);
		}

		return symbol;
	});

	return formattedPhoneArr.join("");
};

export const formatMoney = (amount) => {
	const isFractional = amount.toString().split(".").length === 2;
	let amountLeft;
	let amountRight;

	if (isFractional) {
		amountLeft = amount.toString().split(".")[0];
		amountRight = amount.toString().split(".")[1];
	} else {
		amountLeft = amount;
	}

	const amountArr = (+amountLeft).toString().split("").reverse();
	const formattedArr = [];

	for (let i = 0; i < amountArr.length; i++) {
		if (i % 3 === 0 && i > 0) {
			formattedArr.push(" ", amountArr[i]); // With thin space
			// formattedArr.push(' ', amountArr[i]) // With thin space
		} else {
			formattedArr.push(amountArr[i]);
		}
	}

	if (isFractional) {
		formattedArr.unshift(".");
		formattedArr.unshift(amountRight);
	}

	return formattedArr.reverse().join("");
};

export async function updateUploadPhotoArray(
	loadedPhotoObjectArray,
	localPhotoObjectsArray,
) {
	/* photoObjectsArray = [ { type: "test_type_photo", src: require(./img/1.jpg), title: "testTitle" } ] */
	const templateArray = JSON.parse(JSON.stringify(localPhotoObjectsArray));
	for (const item of templateArray) {
		const res = loadedPhotoObjectArray.find(
			(photo) => photo.type === item.type,
		);
		if (res) {
			try {
				item.src = await loadImageSrc(res.url);
				item.id = res.id;
				item.isCorrect = res.isCorrect ?? false;
			} catch (e) {
				console.warn(e.message);
			}
		}
	}
	return templateArray;
}

export function getGeolocationPermission() {
	if ("permissions" in navigator) {
		return navigator.permissions.query({ name: "geolocation" });
	}

	return Promise.reject("Permissions is not available");
}

export function getGeolocation() {
	if ("geolocation" in navigator) {
		return new Promise((resolve, reject) => {
			navigator.geolocation.getCurrentPosition(resolve, reject);
		});
	}

	return Promise.reject("Geolocation is not available");
}

export async function sendGeolocation(coords) {
	return backReq("geolocation", coords).then((response) => {
		const geolocationId = response?.data?.geolocationId;
		if (geolocationId) {
			localStorage.setItem("geolocationId", geolocationId);
		}
	});
}
