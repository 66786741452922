import { observer } from "mobx-react";
import React, { useEffect, useRef } from "react";
import appStore from "store/app";
import elementsStore from "store/elementsStore";
import { errorsParser } from "utils/errorsParser";
import { isMobile } from "utils/isMobile";
import s from "./OnlineLoanRegistration.module.scss";

import Confirmation from "form-submodule/steps/Confirmation";
import Income from "form-submodule/steps/Income";
import Parameters from "form-submodule/steps/Parameters";
import Personal from "form-submodule/steps/Personal";
import Subject from "form-submodule/steps/Subject";

const steps = [
	{
		title: "Параметры займа",
		tab: "Параметры<br/>займа",
	},
	{
		title: "Персональные данные",
		tab: "Персональные<br/>данные",
	},
	{
		title: "Подтверждение личности",
		tab: "Подтверждение<br/>личности",
	},
	{
		title: "Сведения о доходах",
		tab: "Сведения&nbsp;о доходах",
	},
	{
		title: "Предмет залога",
		tab: "Предмет залога",
	},
];

const OnlineLoanRegistration = observer(({ gap }) => {
	const childRef = useRef();
	useEffect(() => {
		async function fetchData() {
			centerStep();
			if (isMobile()) {
				const mainTitle = document.getElementsByClassName(s.main__title);
				if (mainTitle[0]) {
					mainTitle[0].style.width = `${document.documentElement.clientWidth}px`;
				}
			}
		}

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		centerStep();
		// eslint-disable-next-line
	}, [appStore.backendCurrentStepNumber]);

	useEffect(() => {
		const activeStep = document.getElementsByClassName(s["main__item--active"]);
		if (activeStep.length && activeStep.length > appStore.currentStep)
			activeStep[appStore.currentStep].scrollIntoView({
				block: "center",
				inline: "center",
			});
		// eslint-disable-next-line
	}, [appStore.currentStep]);

	const changeStep = async (event, index) => {
		if (
			appStore.loanDetail.currentStep !== childRef.current?.current &&
			typeof childRef.current?.sendData === "function" &&
			typeof childRef.current?.validate === "function"
		) {
			const isValid = await childRef.current.validate();
			if (!isValid) {
				return false;
			}
			await childRef.current.sendData();
		}
		try {
			await appStore.setCurrentStep(index);
			event.target.scrollIntoView({
				block: "nearest",
				inline: "center",
			});
		} catch (e) {
			elementsStore.showSnackbar(errorsParser(e.responseData ?? e.message));
		}
	};
	const centerStep = () => {
		const activeStep = document.getElementsByClassName(s["main__item--active"]);
		if (activeStep.length)
			activeStep[activeStep.length - 1].scrollIntoView({
				block: "center",
				inline: "center",
			});
	};

	const setMargin = (index, array) => {
		if (index === array.length - 1) {
			return `0 0 0 ${gap}px`;
		}
		if (index === 0) {
			return `0 ${gap}px 0 0 `;
		}
		return `0 ${gap}px`;
	};

	const stepNumber = appStore.currentStep || 0;
	const backendCurrentStepNumber = appStore.backendCurrentStepNumber || 1;
	return (
		<div className={s.main}>
			<div className={s.main__title}>{steps[stepNumber].title}</div>
			<div
				className={`${s.main__tabs} ${appStore.loanDetailLoading ? s.main__tabs_disabled : ""}`}
			>
				{steps.map((step, index, array) => (
					<div
						style={{ margin: setMargin(index, array) }}
						className={[
							s.main__item,
							backendCurrentStepNumber >= index
								? s["main__item--active"]
								: null,
							stepNumber === index ? s["main__item--current"] : null,
						].join(" ")}
						key={index}
						onClick={(event) => {
							if (
								backendCurrentStepNumber >= index &&
								!appStore.loanDetailLoading
							)
								changeStep(event, index);
						}}
					>
						<span dangerouslySetInnerHTML={{ __html: step.tab }} />
					</div>
				))}
			</div>
			{stepNumber === 0 ? (
				<Parameters ref={childRef} />
			) : stepNumber === 1 ? (
				<Personal ref={childRef} />
			) : stepNumber === 2 ? (
				<Confirmation />
			) : stepNumber === 3 ? (
				<Income ref={childRef} />
			) : stepNumber === 4 ? (
				<Subject ref={childRef} />
			) : null}
		</div>
	);
});

export default OnlineLoanRegistration;
