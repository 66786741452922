import React from "react";
import s from './PromoInfo.module.scss'
import BaseButton from "form-submodule/atoms/BaseButton";
import appStore from "store/app";
import {observer} from "mobx-react";

const PromoInfo = observer (() => {

    const openModal = () => {
        if (!localStorage.getItem('loanId')) {
            const calculator = document.getElementById("calculator");
            calculator.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
        } else {
            appStore.setIsLoanModalVisible(true)
        }
    }


    return <section className={s.promoInfo}>
        <div className={'container'}>
            <h2 className={s.title}>Получить деньги <span className={s.colored}>— легко</span></h2>
            <div className={s.stepsWrapper}>
                <div className={s.step}>
                    <div className={s.stepTitleWrapper}>
                        <span className={s.stepNumber}>01</span>
                        <div className={s.line}></div>
                    </div>
                    <div className={s.textWrapper}>
                        <span className={s.stepTitle}>Выберите условия</span>
                        <p className={s.stepDescription}>Выберите необходимую сумму и срок займа, после нажмите на
                            кнопку «Продолжить»</p>
                    </div>
                </div>
                <div className={s.step}>
                    <div className={s.stepTitleWrapper}>
                        <span className={s.stepNumber}>02</span>
                        <div className={s.line}></div>
                    </div>
                    <div className={s.textWrapper}>
                        <span className={s.stepTitle}>Заполните заявку</span>
                        <p className={s.stepDescription}>Заполните заявку, чтобы мы могли принять решения по вашему
                            займу</p>
                    </div>
                </div>
                <div className={s.step}>
                    <div className={s.stepTitleWrapper}>
                        <span className={s.stepNumber}>03</span>
                    </div>
                    <div className={s.textWrapper}>
                        <span className={s.stepTitle}>Получите деньги на карту</span>
                        <p className={s.stepDescription}>Подпишите договор и получите деньги на вашу карту</p>
                    </div>
                </div>
            </div>
            <div className={s.buttonWrapper}>
            <BaseButton size={'medium'} text={'Получить деньги'} onClick={openModal} ></BaseButton>
            </div>
        </div>
    </section>
})
export default PromoInfo
