import BaseInput from "form-submodule/atoms/BaseInput";
import DadataInput from "form-submodule/atoms/DadataInput";
import React from "react";
import s from "./OnlineLoanSubject.module.scss";

const SubjectForm = ({ subjectForm, setSubjectForm }) => {
	const { carMark, carModel, releaseYear, type, colorBody, vin, regNum } =
		subjectForm;

	return (
		<div className={s.inputsGroup}>
			<div>
				<BaseInput
					placeholder="Гос. номер *"
					defaultValue={regNum}
					sessionKey="regNum"
					type="regNum"
					value={(v) => setSubjectForm({ ...subjectForm, regNum: v })}
				/>
			</div>
			<div>
				<BaseInput
					placeholder="Номер кузова или VIN *"
					defaultValue={vin}
					sessionKey="vin"
					type="vin"
					value={(v) => setSubjectForm({ ...subjectForm, vin: v })}
				/>
			</div>
			<div>
				<DadataInput
					placeholder="Марка *"
					defaultValue={carMark.value || carMark}
					sessionKey="carMark"
					type="carMark"
					value={(v) => setSubjectForm({ ...subjectForm, carMark: v.value })}
				/>
			</div>
			<div>
				<BaseInput
					placeholder="Модель *"
					defaultValue={carModel}
					sessionKey="carModel"
					value={(v) => setSubjectForm({ ...subjectForm, carModel: v })}
				/>
			</div>
			<div>
				<BaseInput
					placeholder="Тип транспортного средства *"
					defaultValue={type}
					sessionKey="type"
					value={(v) => setSubjectForm({ ...subjectForm, type: v })}
				/>
			</div>
			<div>
				<BaseInput
					placeholder="Год *"
					defaultValue={releaseYear}
					sessionKey="releaseYear"
					type="onlyNumbers"
					maxlength={4}
					value={(v) => setSubjectForm({ ...subjectForm, releaseYear: v })}
				/>
			</div>
			{/* <div>
				<BaseInput
					placeholder="Номер кузова *"
					defaultValue={bodyNumber}
					sessionKey="bodyNumber"
					upperCase
					value={(v) => setSubjectForm({ ...subjectForm, bodyNumber: v })}
				/>
			</div> */}
			<div>
				<BaseInput
					placeholder="Цвет кузова (из ПТС) *"
					defaultValue={colorBody}
					sessionKey="colorBody"
					value={(v) => setSubjectForm({ ...subjectForm, colorBody: v })}
				/>
			</div>
		</div>
	);
};
export default SubjectForm;
