import BaseButton from "form-submodule/atoms/BaseButton";
import { observer } from "mobx-react";
import React, { useState } from "react";
import elementsStore from "store/elementsStore";
import s from "./ConfirmCodeInput.module.scss";
import ArrowSpinIcon from "./img/arrow-spin.svg";
import ErrorIcon from "./img/error.svg";
import SuccessIcon from "./img/success.svg";

const ConfirmCodeInput = observer(({ onEntered, onSend }) => {
	const [isFullInput, setIsFullInput] = useState(false);

	const onChange = (event) => {
		elementsStore.setSmsCode(event.target.value);
		onEnteredCode();
	};

	const onEnteredCode = () => {
		const smsCodeLength = elementsStore?.smsCode.length;

		if (smsCodeLength >= 6 && !isFullInput) {
			onEntered();
			setIsFullInput(true);
		} else if (smsCodeLength < 6 && isFullInput) {
			setIsFullInput(false);
		}
	};

	const arrowSpinClasses = `${s.ConfirmCodeInput__icon} ${
		elementsStore.checkingSmsCode && s["ConfirmCodeInput__icon--loader"]
	}`;

	const successIconClasses = `${s.ConfirmCodeInput__icon} ${
		elementsStore.smsCodeConfirmed &&
		!elementsStore.checkingSmsCode &&
		s["ConfirmCodeInput__icon--active"]
	}`;

	const errorIconClasses = `${s.ConfirmCodeInput__icon} ${
		!elementsStore.smsCodeConfirmed &&
		!elementsStore.checkingSmsCode &&
		elementsStore?.smsCode.length >= 6 &&
		s["ConfirmCodeInput__icon--active"]
	}`;

	return (
		<div className={s.ConfirmCodeInput}>
			<div className={s.ConfirmCodeInput__wrapper}>
				<div
					className={[
						s.ConfirmCodeInput__placeholder,
						s[
							`${
								elementsStore.smsCode
									? "ConfirmCodeInput__placeholder--active"
									: ""
							}`
						],
					].join(" ")}
				>
					Код из SMS
				</div>
				<input
					className={s.ConfirmCodeInput__field}
					value={elementsStore.smsCode}
					onChange={onChange}
					name="otp"
					type="text"
					inputMode="numeric"
					autoComplete="one-time-code"
					pattern="\d{6}"
					autoFocus
					required
				/>
				<img alt="" className={arrowSpinClasses} src={ArrowSpinIcon} />
				<img alt="" className={successIconClasses} src={SuccessIcon} />
				<img alt="" className={errorIconClasses} src={ErrorIcon} />
			</div>
			{elementsStore.smsCodError && (
				<div
					className={s.ConfirmCodeInput__error}
					dangerouslySetInnerHTML={{
						__html: elementsStore.smsCodError,
					}}
				/>
			)}
			<span className={s.ConfirmCodeInput__timer}>
				{elementsStore.smsResendTimer ? (
					`Получить новый код можно через ${elementsStore.smsResendTimer} сек.`
				) : (
					<BaseButton text="Выслать SMS" type="bony" onClick={onSend} />
				)}
			</span>
		</div>
	);
});

export default ConfirmCodeInput;
