import {action, observable} from "mobx";

class ElementsStore {

    // sms logic
    @observable smsCode = ''
    @action setSmsCode = (value) => this.smsCode = value
    @observable checkingSmsCode = false
    @action setCheckingSmsCode = (bool) => this.checkingSmsCode = bool
    @observable smsCodeConfirmed = false
    @action setSmsCodeConfirmed = (bool) => this.smsCodeConfirmed = bool
    @observable smsCodError = null
    @action setSmsCodeError = (value) => this.smsCodError = value
    @observable smsResendTimer = 0
    @action setSmsResendTimer = (seconds) => this.smsResendTimer = seconds
    @action smsInputRestoreDefault = () => {
        this.smsCode = ''
        this.checkingSmsCode = false
        this.smsCodeConfirmed = false
        this.smsCodError = null
        this.smsResendTimer = 0
    }
    @action async callSmsAction(callback, seconds=60 ){
        if (this.smsResendTimer === 0) {
            try {
                const response = await callback()
                if (response.errors) return response
                this.startSmsCountdown(response.data?.seconds ?? seconds)
                return response
            } catch (e) {
                throw e
            }
        } else {
            return {}
        }
    }

    interval = undefined
    @action startSmsCountdown = (value) => {
        this.smsResendTimer = value
        clearInterval(this.interval);
        this.startTime()
    }
    startTime = () =>{
        if (!this.smsResendTimer) return;
        this.interval = setInterval(() => {
            if (this.smsResendTimer > 0) {
                this.smsResendTimer = this.smsResendTimer - 1
            }
            else {
                clearInterval(this.interval);
            }
        }, 1000)
    }

    // snackbar logic
    @observable snackbarIsOpen = false
    @observable snackbarMessage = ''
    @observable snackbarMessageType = 'info'
    @observable snackbarDuration = 5000
    @action closeSnackbar(){
        this.snackbarIsOpen = false
    }
    @action showSnackbar = (message, type='error', duration=5000) => {
        this.snackbarMessage = message
        this.snackbarMessageType = type
        this.snackbarDuration = duration
        this.snackbarIsOpen = true
        setTimeout(()=>this.closeSnackbar(), this.snackbarDuration)
    }

    // change step button loading
    @observable isChangeStepButtonLoading = false
    @action setIsChangeStepButtonLoading = (bool) => this.isChangeStepButtonLoading = bool

}

const elementsStore = new ElementsStore()
export default elementsStore
