import DadataInput from "form-submodule/atoms/DadataInput";
import PhoneInput from "form-submodule/atoms/PhoneInput";
import NativeSelect from "form-submodule/molecules/NativeSelect";
import React from "react";
import appStore from "store/app";
import s from "./OnlineLoanIncome.module.scss";

const ContactPersonForm = ({
	contactForm,
	setContactForm,
	required = false,
}) => {
	const { contact, fio, phone, address } = contactForm;

	const { whomDependenceValues = [] } = appStore.loanRemoteFieldValues;

	const setContactAddress = (x) => {
		setContactForm({
			...contactForm,
			address: x.value,
			addressObj: x.data || {},
		});
	};

	return (
		<div className={s.inputsGroup}>
			<div>
				<NativeSelect
					placeholder="Кем является *"
					defaultValue={contact}
					value={(x) => setContactForm({ ...contactForm, contact: x })}
					options={whomDependenceValues}
					onChanged={(x) => setContactForm({ ...contactForm, contact: x })}
					onSearch={null}
					required={required}
				/>
			</div>
			<div>
				{/* <BaseInput
                placeholder="Фамилия Имя Отчество"
                defaultValue={fio}
                value={(x) => setContactForm({...contactForm, fio:x})}
                onlyLetters
            /> */}
				<DadataInput
					type="fio"
					placeholder="Фамилия Имя Отчество *"
					defaultValue={fio.value || fio}
					value={(v) => setContactForm({ ...contactForm, fio: v.value || "" })}
					onlyLetters
					required={required}
				/>
			</div>
			<div>
				<PhoneInput
					placeholder="Номер телефона *"
					defaultValue={phone}
					value={(x) => setContactForm({ ...contactForm, phone: x })}
					required={required}
				/>
			</div>
			<div className={s.row}>
				<DadataInput
					type="address"
					placeholder="Адрес проживания (Город, улица, дом) *"
					defaultValue={address}
					value={setContactAddress}
					required={required}
				/>
			</div>
		</div>
	);
};
export default ContactPersonForm;
