import React from "react";
import ModalContainer from "form-submodule/atoms/ModalContainer";
import s from "./Calculator.module.scss"
import ReactDOM from "react-dom";
import {VI_OFFICE_PHONE, VI_OFFICE_PHONE_URL} from "constants/phones";

const MoreMoneyModal = ({ showModal, close}) => {
  return ReactDOM.createPortal(
    <ModalContainer
      title={"Повышение суммы займа"}
      closeModal={close}
      showModal={showModal}
    >
      <div className={s.moreMoneyModal}>
      <p>
        Для получения большего объема денежных средств - мы приглашаем
        Вас в один из наших офисов, мы оперативно подберем оптимальное финансовое решение!
      </p>
      <span>
        Горячая линия:  <a href={VI_OFFICE_PHONE_URL}>{VI_OFFICE_PHONE}</a>
      </span>
      </div>
    </ModalContainer>
    , document.body)
};
export default MoreMoneyModal;
