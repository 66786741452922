import { Input } from 'antd';
import useDebounce from "hooks/useDebounce";
import { ReactComponent as SuccessTrimSvg } from 'img/success_trim.svg'
import React, { useEffect, useState, useRef } from "react";
import onSuggestion from "utils/onSuggestion";
import s from './DadataInput.module.scss'

const { TextArea } = Input;

const DadataInput = ({
    type,
    defaultValue,
    value,
    placeholder = '',
    disabled,
    required,
    isCompany,
    sessionKey,
    onlyLetters,
    query,
    onSelect,
}) => {
    const [prevValue, setPrevValue] = useState('');
    const [localValue, setLocalValue] = useState('');
    const [isFocused, setIsFocused] = useState(false);
    const [valuesArray, setValuesArray] = useState(null);
    const [isValid, setIsValid] = useState(false);
    const debouncedValue = useDebounce(localValue, 300);
    const debouncedQuery = useDebounce(query, 300);

    const inputRef = useRef();

    const wrapperClasses = [s.inputWrapper]
    if (localValue || type === 'address') {
        wrapperClasses.push(s.activePlaceholder)
    }
    if (disabled) wrapperClasses.push(s.disabledPlaceholder);
    const [active, setActive] = useState(0);

    useEffect(() => {
        if (!prevValue && defaultValue && prevValue !== defaultValue) {
            setLocalValue(defaultValue)
            setIsValid(true)
            setPrevValue(defaultValue)
        }

        if (
            debouncedValue
            && localValue === debouncedValue
            && defaultValue !== debouncedValue
            && defaultValue !== localValue
            && isFocused
        ) {
            setTimeout(() => {
                search(debouncedValue);
            })
        }
        // eslint-disable-next-line
    }, [defaultValue, debouncedValue]);

    useEffect(() => {
        if (
            type === 'fmsUnit'
            && debouncedQuery.length >= 3
        ) {
            search(debouncedQuery);
            setIsFocused(true);
        }
        // eslint-disable-next-line
    }, [debouncedQuery])

    const search = async (queryString) => {
        setActive(0);
        setValuesArray(null)
        const arr = await onSuggestion(queryString, type === 'address' && isCompany ? 'addressCompany' : type)
        if (arr && isFocused) {
            setValuesArray(arr)
        }
    }

    const onChange = (queryString) => {
        if (!queryString) {
            setIsValid(false)
        }
        let formattedValue

        if (type === 'email') {
            formattedValue = queryString.replace(/[^a-zA-Z0-9@_.+-]+/, '')
        } else {
            formattedValue = onlyLetters
                ? queryString.replace(/[^a-zA-Zа-яА-Я-\s]+/, '')
                : queryString
        }
        setLocalValue(formattedValue)
    };

    const onMouseDown = (index) => {
        setIsFocused(false)
        const item = valuesArray[index]
        value(item)
        if (typeof onSelect === 'function') {
            onSelect(item)
            inputRef.current.focus();
        }
        setLocalValue(item.value)
        setIsValid(true)
        if (sessionKey) {
            sessionStorage.setItem(sessionKey, JSON.stringify(item))
        }

        setActive(0)
        setValuesArray(null)
    };

    const onBlur = () => {
        setIsFocused(false);
        setActive(0)
        setValuesArray(null)
        value({ value: localValue })

        if (sessionKey) {
            sessionStorage.setItem(sessionKey, localValue)
        }
        if (localValue.trim()) {
            setIsValid(true)
        }
    }

    const onFocus = () => {
        setIsFocused(true);
    }

    const onKeyDown = (e) => {
        switch (e.keyCode) {
            case 13: {
                e.preventDefault();
                onMouseDown(active)
                break;
            }
            case 38: {
                if (active !== 0) {
                    setActive(active - 1);
                }
                break;
            }
            case 40: {
                if (active !== valuesArray.length - 1) {
                    setActive(active + 1);
                }
                break;
            }
            default: return
        }
    };

    return <div className={s.dadataWrapper}>
        <div className={s.container} >
            <div className={wrapperClasses.join(' ')}>
                <div className={s.placeholder}>{placeholder}</div>
                {type === 'address' || type === 'fmsUnit'
                    ?
                    <TextArea
                        ref={inputRef}
                        onChange={e => onChange(e.currentTarget.value)}
                        onKeyDown={onKeyDown}
                        value={localValue}
                        disabled={disabled}
                        required={required}
                        className={`${s.input} ${s.textarea}`}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        autoComplete="off"
                        role="presentation"
                        autoSize
                    />
                    :
                    <input
                        ref={inputRef}
                        type="text"
                        onChange={e => onChange(e.currentTarget.value)}
                        onKeyDown={onKeyDown}
                        value={localValue}
                        disabled={disabled}
                        required={required}
                        className={s.input}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        autoComplete="off"
                        role="presentation"
                    />
                }
            </div>
            {isValid && <div className={s.iconWrapper}>
                <SuccessTrimSvg />
            </div>
            }
            {
                Array.isArray(valuesArray) && <ul className={`${s.optionWrapper} ${s.optionWrapper_high}`}>
                    {valuesArray.map((suggestion, index) => {
                        const className = [s.option];
                        if (index === active) {
                            className.push(s.active)
                        }
                        return (
                            <li
                                className={className.join(' ')}
                                key={`${suggestion.value}-${index}`}
                                onMouseDown={() => onMouseDown(index)}
                            >
                                {suggestion.value}
                            </li>
                        );
                    })}
                </ul>
            }
        </div>
        {type === 'address' &&
            <div className={s.hint}>
                {isCompany ? 'Пример правильного ввода адреса: г Новосибирск, ул Фабричная, д 55'
                    : '*Укажите полный адрес (до квартиры). Если у вас частный дом, укажите номер кв. 1'
                }
            </div>
        }

    </div>

}
export default DadataInput
