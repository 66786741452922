// polyfills
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from "@sentry/react";

import { Provider } from 'mobx-react'

import 'styles/index.scss'

import App from "App";
import appStore from 'store/app'
import ResizeProvider from "providers/ResizeProvider";
import elementsStore from "store/elementsStore";

Sentry.init({
    dsn: "https://36b5fdc5ecdbfb21c85276ad6ece30bc@sentry.vashinvestor.ru/19",
});

const render = Component => {
  return ReactDOM.render(
    <Provider appStore={appStore} elementsStore={elementsStore}>
      <ResizeProvider>
        <Component />
      </ResizeProvider>
    </Provider>,
    document.getElementById('root')
  )}

render(App)

if (module.hot) {
  module.hot.accept(App, _ => {
    const NextApp = App.default
    render(NextApp)
  })
}
