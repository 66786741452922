import onSuggestion from "./onSuggestion";

export const validateEmail = (email) => {
	return String(email)
		.toLowerCase()
		.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
		);
};
export const isValidEmail = (email) => {
	const re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
};

export const validatePhone = (phone) => {
	const operatorCode = phone[4];
	if ([0, 1, 2, 7].some((number) => number === +operatorCode)) {
		return "Проверьте код оператора или региона — он может начинаться на 3, 4, 5, 6, 8, 9";
	}
	if (phone.slice(4, 6) === "89") {
		return "Проверьте код оператора или региона";
	}
	return false;
};
export function isItemHasSrc(item) {
	return Boolean(item.src);
}
export function isItemSrcEmpty(item) {
	return !isItemHasSrc(item);
}
export const validateAddress = async (address = "", isCompany = false) => {
	let requiredFields = [
		"flat",
		"region_with_type",
		"house",
		"okato",
		"fias_id",
		"country_iso_code",
		"region_fias_id",
	];
	if (isCompany) {
		requiredFields = requiredFields.slice(1);
	}
	const response = await onSuggestion(address);

	const { data } = response?.[0] || {};

	return requiredFields.every(
		(field) => data?.hasOwnProperty(field) && data[field],
	);
};
export const validateInn = (value) => {
	if (
		typeof value !== "string" ||
		(value.length !== 10 && value.length !== 12) ||
		value.split("").some((symbol) => Number.isNaN(Number(symbol)))
	)
		return false;

	if (value.length === 10) {
		return (
			Number(value[9]) ===
			(value
				.split("")
				.slice(0, -1)
				.reduce(
					(summ, symbol, index) =>
						[2, 4, 10, 3, 5, 9, 4, 6, 8][index] * Number(symbol) + summ,
					0,
				) %
				11) %
				10
		);
	}
	if (value.length === 12) {
		const checkSumOne =
			(value
				.split("")
				.slice(0, -2)
				.reduce(
					(summ, symbol, index) =>
						[7, 2, 4, 10, 3, 5, 9, 4, 6, 8][index] * Number(symbol) + summ,
					0,
				) %
				11) %
			10;

		const checkSumTwo =
			(value
				.split("")
				.slice(0, -1)
				.reduce(
					(summ, symbol, index) =>
						[3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8][index] * Number(symbol) + summ,
					0,
				) %
				11) %
			10;

		return (
			checkSumOne === Number(value[10]) && checkSumTwo === Number(value[11])
		);
	}
};
export const validateDate = (date) => {
	if (!date) return false;
	try {
		const checkedDate = new Date(date);
		const dateNow = new Date();
		const dateStart = new Date("1950-01-01");
		return checkedDate >= dateStart && checkedDate <= dateNow;
	} catch (e) {
		return false;
	}
};
