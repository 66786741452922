import React, { useEffect, useRef, useState } from 'react'
import { Tooltip } from "antd";

import { isTablet } from "utils/isTablet";
import s from './BasePhotoPreviewUpload.module.scss'
import UploadPenImg from './img/edit.svg'
import UploadCloudImg from './img/upload-cloud.svg'
import TrashImg from './img/trash.svg'
import SuccessImg from './img/success.svg'
import RedCross from './img/red-cross.svg'
import CheckImg from 'img/check.svg'
import CrossImg from 'img/cross.svg'
import BaseLoader from "form-submodule/atoms/BaseLoader";
import { baseCloudSrc } from "utils/templates/photoArrayTemplates";
import BaseButton from 'form-submodule/atoms/BaseButton';
import { EyeIcon } from 'form-submodule/icons/eye';
import { CrossIcon } from 'form-submodule/icons/cross';

const BasePhotoPreviewUpload = ({
    src,
    type,
    baseSrc = baseCloudSrc,
    onLoad,
    onDelete,
    title,
    errorText = '',
    disabled = false,
    isCorrect = false,
    subTitle = '',
    placeholderWidth,
    forceTooltip = false,
    tooltip = '',
}) => {
    const [loading, setLoading] = useState(false)
    const [showTooltip, setShowTooltip] = useState(false)
    const [isFirstLoad, setIsFirstLoad] = useState(sessionStorage.getItem(`${type}_first_load`) ? JSON.parse(sessionStorage.getItem(`${type}_first_load`)) : true)
    const fileInput = useRef(null);
    const cardAction = useRef(null);
    const cardPhoto = useRef(null);
    const isUploaded = src && (src !== baseSrc)

    useEffect(() => {
        isTablet() && document.addEventListener('click', clickOutside)

        return () => {
            isTablet() && document.removeEventListener('click', clickOutside)
        }
    })

    const clickOutside = (event) => {
        if (cardPhoto.current && cardAction.current && !cardPhoto.current.contains(event.target)) {
            cardAction.current.classList.remove(s.active)
        }
    }

    const tabletValidator = (event) => {
        if (!src) {
            event.stopPropagation()
            triggerInputEvent(event)
            return;
        }
        if (!isTablet() || !src) return;
        if (!cardAction.current.classList.contains(s.active)) {
            event.stopPropagation()
        }
        cardAction.current.classList.toggle(s.active)
    }

    const triggerInputEvent = (event) => {
        event.preventDefault()
        if (forceTooltip && isFirstLoad) {
            setShowTooltip(true)
        } else {
            fileInput.current && fileInput.current.click();
        }
    }

    const handleTooltipOpenChange = (open) => {
        if (forceTooltip && isFirstLoad && !open) {
            setIsFirstLoad(false);
            sessionStorage.setItem(`${type}_first_load`, false);
            fileInput.current && fileInput.current.click();
        }
        setShowTooltip(open);
    }

    async function onFileLoad(event) {
        event.stopPropagation()
        try {
            setLoading(true)
            await onLoad(event)
            // hint to avoid upload same file
            event.target.value = ""
        } catch (e) {
            console.warn('BasePhotoPreview upload photo error')
        } finally {
            setLoading(false)
        }
    }

    const onFileDelete = async (event) => {
        if (isCorrect) return;
        event.stopPropagation()
        try {
            setLoading(true)
            await onDelete()
        } catch (e) {
            console.warn('BasePhotoPreview upload photo error')
        } finally {
            setLoading(false)
        }
    }

    const handleTooltipBtn = () => {
        setShowTooltip(false);
        setIsFirstLoad(false);
        sessionStorage.setItem(`${type}_first_load`, false);
        fileInput.current && fileInput.current.click();
    }

    function renderPhoto() {
        if (loading) {
            return <div style={{ position: 'relative' }}><BaseLoader /></div>
        }
        if (isUploaded) return <img className={s.uploadCard__userPhoto} src={src} alt="" />
        if (baseSrc.includes('cloud')) {
            return <div onClick={triggerInputEvent} className={s.uploadCard__default}>
                <img className={s.uploadCard__userPhoto} src={UploadCloudImg} alt="" />
                <span>Загрузить</span>
            </div>
        }
        return <img
            className={`${s.uploadCard__placeholder} ${!title ? s.uploadCard__placeholder_wide : ''}`}
            onClick={triggerInputEvent}
            style={{
                width: placeholderWidth ? placeholderWidth : 'initial'
            }}
            src={baseSrc}
            alt=""
        />
    }

    const renderPhotoActionsTemplate = () => {
        if (loading || disabled || !src) return null

        return <div ref={cardAction} className={s.uploadCard__action}>
            <div onClick={triggerInputEvent} className={s.uploadCard__actionBtn}>
                <img src={UploadPenImg} alt="" />
                <span className={s.changeButton}>Изменить</span>
            </div>
            {!isCorrect && <div onClick={onFileDelete} className={s.uploadCard__actionBtn}>
                <img src={TrashImg} alt="" />
                <span className={s.deleteButton}>Удалить</span>
            </div>}
        </div>
    }

    const renderIcon = () => {
        if (src === baseCloudSrc) return null
        return src ?
            errorText ? <img className={s.uploadCard__success} src={RedCross} alt="" /> :
                <img className={s.uploadCard__success} src={SuccessImg} alt="" />
            : null
    }

    return (
        <div className={s.uploadCard}>
            <div className={s.uploadCard__info}>
                <div
                    className={`${s.uploadCard__photo} ${isUploaded ? '' : s.notUploaded} ${errorText ? s.errorFrame : ''}`}
                    ref={cardPhoto}
                    onClickCapture={tabletValidator}
                >
                    {errorText && <div className={s.errorText}>
                        <img className={s.uploadCard__error} src={RedCross} alt="" />
                        <p>{errorText}</p>
                    </div>}
                    {!errorText && renderPhoto()}
                    {renderIcon()}
                    {renderPhotoActionsTemplate()}
                    {!isUploaded && title && <p className={s.title}>{title}</p>}
                </div>
                {subTitle && <div className={s.subTitle}>
                    {subTitle}
                    {tooltip &&
                        <Tooltip
                            onOpenChange={handleTooltipOpenChange}
                            open={showTooltip}
                            trigger={'click'}
                            overlayStyle={{
                                maxWidth: 340,
                            }}
                            overlayInnerStyle={{
                                padding: 24,
                                borderRadius: 16,
                            }}
                            title={
                                <div className={s.tooltipWrapper} onClick={() => handleTooltipOpenChange(false)}>
                                    <div className={s.tooltipBody}>
                                        {tooltip.map((item, index) => {
                                            switch (item.type) {
                                                case 'right':
                                                    return <div className={s.tooltipItem} key={`${item.type}-${index}`}>
                                                        <img src={CheckImg} alt="" />
                                                        <p dangerouslySetInnerHTML={{ __html: item.text }}></p>
                                                    </div>
                                                case 'wrong':
                                                    return <div className={s.tooltipItem} key={`${item.type}-${index}`}>
                                                        <img src={CrossImg} alt="" />
                                                        <p dangerouslySetInnerHTML={{ __html: item.text }}></p>
                                                    </div>
                                                case 'image':
                                                    return <div className={s.tooltipImageWrapper} key={`${item.type}-${index}`}>
                                                        <img
                                                            className={s.tooltipImg}
                                                            src={item.src}
                                                            alt=""
                                                            width={item.width || 'auto'}
                                                            height={item.height || 'auto'}
                                                        />
                                                    </div>
                                                default:
                                                    return null;
                                            }
                                        })}
                                        {forceTooltip &&
                                            <BaseButton text="Загрузить" onClick={handleTooltipBtn} />
                                        }
                                    </div>
                                </div>
                            }
                        >
                            <span className={s.tooltipLink}>{showTooltip ? <CrossIcon /> : <EyeIcon />} Пример</span>
                        </Tooltip>
                    }
                </div>}
            </div>
            {!disabled && <input ref={fileInput} onChange={onFileLoad} type="file" />}
        </div>
    )
}

export default BasePhotoPreviewUpload
