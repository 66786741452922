import React, {useEffect, useState} from 'react'
import { useLocation } from 'react-router-dom';
import s from './Header.module.scss'
import BaseLogo from "form-submodule/atoms/BaseLogo";
import {VI_OFFICE_PHONE, VI_OFFICE_PHONE_URL} from "constants/phones";
import {isMobile} from "utils/isMobile";
import CallIcon from 'img/callWithPhone.png'

const Header = () => {
    const { search } = useLocation();

    const callToOperator = () => {
        window.open(VI_OFFICE_PHONE_URL)
    }

    // eslint-disable-next-line
    const [isHide, setIsHide] = useState(0);
    const handleScroll = () => {
        const position = window.scrollY;
        setIsHide(position > 1);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return <header className={s.header}>
        <div className={'container'}>
            <div className={s.header__wrapper}>
                <a href={`https://vashinvestor.ru/${search}`}>
                    <BaseLogo/>
                </a>
                {
                    isMobile()
                        ? <img src={CallIcon} alt="call" onClick={callToOperator}/>
                        : <a href={VI_OFFICE_PHONE_URL} className={s.header__phone}>{VI_OFFICE_PHONE}</a>
                }
            </div>
        </div>
    </header>;
}

export default Header
