import React, { useEffect, useReducer, useState } from "react";
import BasePhotoPreviewUpload from "form-submodule/atoms/BasePhotoPreviewUpload";
import { uploadOnlineLoanPhoto } from "utils/imageLoader";
import { observer } from "mobx-react";
import { backReq, updateUploadPhotoArray } from "helpers";
import elementsStore from "store/elementsStore";
import { errorsParser } from "utils/errorsParser";
import appStore from "store/app";
import PhotoPreviewUpload from "form-submodule/atoms/PhotoPreviewUpload";

const OnlineLoanPhotoArray = observer(({ photoArray = [], updateFunction }) => {
    // PhotoModel = {
    //     src: '',
    //     title:'',
    //     type: '',
    //     disabled: false,
    //     errorText: '',
    //     isCorrect: false
    // }
    //     photoArray = PhotoModel[]

    const [localArray, setLocalArray] = useState([])
    // eslint-disable-next-line no-unused-vars
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

    useEffect(() => {
        setLocalArray(JSON.parse(JSON.stringify(photoArray)))
    }, [photoArray])

    useEffect(() => {
        const photoArray = appStore?.loanDetail?.photos?.length ? appStore.loanDetail.photos : []
        loadPhotoFromDatabase(photoArray)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appStore.loanDetail.photos])

    const loadPhotoFromDatabase = async (photos) => {
        let loadedPhotoArray = await updateUploadPhotoArray(photos, photoArray)
        updateFunction(loadedPhotoArray)
    }

    function addFile(type) {
        return async function (event) {
            try {
                const { blobURL, id } = await uploadOnlineLoanPhoto(type, event.target.files[0])
                const updatePhotoIndex = localArray.findIndex(item => type === item.type)
                localArray[updatePhotoIndex].src = blobURL
                localArray[updatePhotoIndex].id = id
                localArray[updatePhotoIndex].errorText = ''
                updateFunction(localArray)
                setLocalArray(localArray)
                forceUpdate();
            } catch (e) {
                elementsStore.showSnackbar(errorsParser(e.data))
            }
        }
    }

    const removePhoto = async ({ id }) => {
        if (!id) return
        try {
            const sendResponse = await backReq('delete_photo', {
                id: appStore.loanDetail.id,
                photoId: id
            })
            if (sendResponse.errors) {
                elementsStore.showSnackbar(errorsParser(sendResponse.errors))
                return
            }
            const updatePhotoIndex = localArray.findIndex(item => item.id === id)
            localArray[updatePhotoIndex].src = null
            localArray[updatePhotoIndex].id = null
            updateFunction(localArray)
            setLocalArray(localArray)
            forceUpdate();
        } catch (e) {
            console.warn(e)
        }
    }

    return localArray.map(item => (
        item.tipText
            ? <PhotoPreviewUpload
                key={item.type}
                type={item.type}
                src={item.src}
                baseSrc={item.baseSrc}
                tipText={item.tipText}
                rightSrc={item.rightSrc}
                wrongSrc={item.wrongSrc}
                title={item.title}
                disabled={item.disabled}
                errorText={item.errorText}
                isCorrect={item.isCorrect}
                onLoad={addFile(item.type)}
                onDelete={() => removePhoto(item)}
                placeholderWidth={item.placeholderWidth}
            />
            : <BasePhotoPreviewUpload
                key={item.type}
                type={item.type}
                src={item.src}
                baseSrc={item.baseSrc}
                title={item.title}
                subTitle={item.subTitle}
                disabled={item.disabled}
                errorText={item.errorText}
                isCorrect={item.isCorrect}
                onLoad={addFile(item.type)}
                onDelete={() => removePhoto(item)}
                placeholderWidth={item.placeholderWidth}
                forceTooltip={item.forceTooltip}
                tooltip={item.tooltip}
            />
    ))
})
export default OnlineLoanPhotoArray
