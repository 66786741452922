import { loanStepsArray } from "constants/onlineLoan";
import BaseButton from "form-submodule/atoms/BaseButton";
import BaseHoneypot from "form-submodule/atoms/BaseHoneypot";
import ConfirmCodeInput from "form-submodule/atoms/ConfirmCodeInput/ConfirmCodeInput";
import ModalPortal from "form-submodule/atoms/ModalPortal";
import PhoneInput from "form-submodule/atoms/PhoneInput";
import { moveToNextOnlineLoanStep } from "form-submodule/steps/helpers";
import {
	backReq,
	backReqV2,
	getFormattedPhone,
	getIsoDateStringWithTimezone,
} from "helpers";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import appStore from "store/app";
import elementsStore from "store/elementsStore";
import { CPA, UTM, deleteCookie } from "utils/cookie";
import { errorsParser } from "utils/errorsParser";
import { trimmedPhoneWithPlus } from "utils/formattedPhone";
import getYaId from "utils/getYaId";
import { yandexMetrikaReachGoal } from "utils/yandex-metrika";
import s from "./Calculator.module.scss";

const CalculatorAction = observer(({ onContinue, setIsSigned, referCode }) => {
	const [modal, setModal] = useState(false);
	const [sentPhone, setSentPhone] = useState("");
	const [referCodeValue, setReferCodeValue] = useState(referCode);
	const [phone, setPhone] = useState("");
	const { loanTerm, desiredAmount, id } = appStore.loanDetail;
	const [token, setToken] = useState("");

	useEffect(() => {
		sessionStorage.removeItem("captchaToken");

		const handleCaptcha = () => {
			setToken(sessionStorage.getItem("captchaToken"));
		};

		window.addEventListener("captcha", handleCaptcha);
		return () => window.removeEventListener("captcha", handleCaptcha);
	}, []);

	async function sendSms() {
		const response = await elementsStore.callSmsAction(() =>
			backReq("get_phone_code_free", {
				phone,
				token,
			}),
		);
		if (response.errors) {
			elementsStore.showSnackbar(errorsParser(response.errors));
		}
	}

	const onEnteredCode = async () => {
		elementsStore.setCheckingSmsCode(true);

		try {
			const utm = sessionStorage.getItem(CPA) || localStorage.getItem(UTM);
			const geolocationId = localStorage.getItem("geolocationId");
			const isoDateStringWithTimezone = getIsoDateStringWithTimezone();
			const response = await backReq("check_phone_code_and_get_id", {
				code: elementsStore.smsCode,
				phone,
				personalAgreementTimestamp: isoDateStringWithTimezone,
				...(referCodeValue && { referCode: referCodeValue }),
				...(utm && { utmUrl: utm }),
				...(geolocationId && { geolocationId }),
			});
			if (response.errors) {
				const ignoreText = "Неверный код";
				elementsStore.showSnackbar(
					response?.errors?.code === ignoreText
						? ignoreText
						: errorsParser(response.errors),
				);
				return false;
			}
			appStore.setLoanDetail({ ...appStore.loanDetail, id: response.data.id });
			await backReqV2("params", {
				id: response.data.id,
				desiredAmount,
				loanTerm,
				personalDataAgreement: true,
				// заглушка для обязательного поля. Сейчас скрыто на фронте, но есть на бэке
				loanPurpose: "Прочие личные нужды",
				yandexId: getYaId(),
			});
			// await validateStep(loanStepsArray[0])
			await moveToNextOnlineLoanStep(loanStepsArray[1]);

			elementsStore.setSmsCodeConfirmed(true);
			setIsSigned(true);
			setTimeout(async () => {
				elementsStore.smsInputRestoreDefault();
				setModal(false);
				if (utm) {
					deleteCookie(UTM);
					localStorage.removeItem(UTM);
					sessionStorage.removeItem(CPA);
				}
				// await onContinue()
				yandexMetrikaReachGoal("online_step1");
				appStore.setIsLoanModalVisible(true);
			}, 300);
		} catch (e) {
			console.warn(e);
		} finally {
			elementsStore.setCheckingSmsCode(false);
		}
	};

	const openConfirmModal = async () => {
		if (phone.length < 12) {
			elementsStore.showSnackbar("Введите номер телефона");
			return;
		}
		if (!!sentPhone && sentPhone !== phone) {
			elementsStore.smsInputRestoreDefault();
		}
		setSentPhone(phone);
		await sendSms();
		setModal(true);
	};

	const handleGetMoney = () => {
		if (window.location.hostname.includes("business")) {
			yandexMetrikaReachGoal("online_business_poluchit");
		}
		if (!id) {
			openConfirmModal();
		} else {
			onContinue();
		}
	};

	return (
		<div className={s.phoneWrapper}>
			<div className={s.phone}>
				<input
					style={{
						display: "none",
					}}
					type="text"
					name="referCode"
					defaultValue={referCode || ""}
					onChange={(v) => setReferCodeValue(v)}
				/>
				<BaseHoneypot type="text" name="phone" />
				{!id && (
					<>
						<PhoneInput
							type="number"
							defaultValue={phone}
							value={(x) => setPhone(trimmedPhoneWithPlus(x))}
							placeholder="Номер телефона"
						/>
						<div
							style={{ height: 100 }}
							id="captcha-container"
							className="smart-captcha"
							data-sitekey="ysc1_4SguzSZoZEvwz11FAqZceJj7QOFkzN27hDWyI46W23d923ab"
							data-hl="ru"
							data-callback="smartCaptchaCallback"
						/>
					</>
				)}
				<BaseButton
					text={!!id ? "Продолжить оформление" : "Получить деньги"}
					size={"medium"}
					onClick={handleGetMoney}
					disabled={!token && !id}
				/>
			</div>
			<ModalPortal showModal={modal} closeModal={() => setModal(false)}>
				<div className={s.phoneModal}>
					<p>
						Мы отправили код доступа на номер{" "}
						<span style={{ whiteSpace: "nowrap" }}>
							{getFormattedPhone(phone)}.
						</span>
					</p>
					<p>Введите код из SMS:</p>
					<ConfirmCodeInput onEntered={onEnteredCode} onSend={sendSms} />
				</div>
			</ModalPortal>
		</div>
	);
});
export default CalculatorAction;
