import React, { useState } from "react";
import s from "./OnlineLoanIncome.module.scss";
import NativeSelect from "form-submodule/molecules/NativeSelect";
import BaseInput from "form-submodule/atoms/BaseInput";
import BaseCheckbox from "form-submodule/atoms/BaseCheckbox";
// import PhoneInput from "form-submodule/atoms/PhoneInput";
// import { maritalStatusTemplate } from "utils/templates/martialStatusTemplate";
import DadataInput from "form-submodule/atoms/DadataInput";
import appStore from "store/app";
import { observer } from "mobx-react";
import ModalPortal from 'form-submodule/atoms/ModalPortal';

const IncomeForm = observer(({ incomeForm, setIncomeForm }) => {
    const [isSpecialModalOpen, setIsSpecialModalOpen] = useState(false)
    const {
        employmentStatusValues = [],
        fieldOfActivityValues = [],
    } = appStore.loanRemoteFieldValues

    const {
        employmentStatus,
        fieldOfActivity,
        // maritalStatus,
        employmentPosition,
        company,
        // workPhone,
        companyAddress,
        income,
        // additionalIncome,
        // additionalIncomeSource,
        creditMonthSum,
        publicPerson,
    } = incomeForm

    // const setCompanyAddress = x => {
    //     setIncomeForm({
    //         ...incomeForm,
    //         companyAddress: x.value,
    //         companyAddressObj: x.data || {}
    //     })
    // }
    const handlePublicPersonChanged = () => {
        setIncomeForm({ ...incomeForm, publicPerson: !publicPerson })
        sessionStorage.setItem('publicPerson', !publicPerson)
        if (!publicPerson) {
            setIsSpecialModalOpen(true)
        }
    }

    return <div className={s.inputsGroup}>
        <div>
            <NativeSelect
                placeholder="Статус трудоустройства *"
                defaultValue={employmentStatus}
                sessionKey="employmentStatus"
                options={employmentStatusValues}
                onChanged={(x) => setIncomeForm({ ...incomeForm, employmentStatus: x })}
                onSearch={null}
            />
        </div>
        <div>
            <NativeSelect
                placeholder="Сфера деятельности *"
                defaultValue={fieldOfActivity}
                sessionKey="fieldOfActivity"
                options={fieldOfActivityValues}
                onChanged={(x) => setIncomeForm({ ...incomeForm, fieldOfActivity: x })}
                onSearch={null}
            />
        </div>
        {/* <div>
            <NativeSelect
                placeholder="Семейное положение *"
                options={maritalStatusTemplate}
                defaultValue={maritalStatus?.label || ""}
                sessionKey="maritalStatus"
                withLabel={true}
                onSearch={null}
                onChanged={(x) => setIncomeForm({...incomeForm, maritalStatus: x})}
            />
        </div>
         */}
        <div>
            <DadataInput
                type="company"
                placeholder="Наименование компании *"
                defaultValue={company.value || company}
                sessionKey="company"
                value={(x) => setIncomeForm({ ...incomeForm, company: x.value })}
            />
            {/* <BaseInput
                placeholder="Наименование компании *"
                defaultValue={company}
                sessionKey="company"
                value={(x) => setIncomeForm({ ...incomeForm, company: x })}
            /> */}
        </div>

        {/* <div>
            <PhoneInput
                placeholder="Рабочий телефон"
                defaultValue={workPhone}
                sessionKey="workPhone"
                value={(x) => setIncomeForm({...incomeForm, workPhone: x})}
            />
        </div> */}
        <div className={s.wideColumn}>
            {/* <div>
                <BaseInput
                    placeholder="Фактический адрес компании *"
                    defaultValue={companyAddress}
                    sessionKey="companyAddress"
                    value={(x) => setIncomeForm({ ...incomeForm, companyAddress: x })}
                />
                <div className={s.hint}>
                    Пример правильного ввода адреса: г Новосибирск, ул Фабричная, д 55
                </div>
            </div> */}

            <DadataInput
                type="address"
                placeholder="Фактический адрес компании (Город, улица, дом) *"
                defaultValue={companyAddress?.value || companyAddress}
                sessionKey="companyAddress"
                value={(x) => setIncomeForm({ ...incomeForm, companyAddress: x.value })}
                isCompany={true}
            />
        </div>
        <div>
            <BaseInput
                placeholder="Общий доход, ₽ *"
                defaultValue={income}
                sessionKey="income"
                type="onlyNumbers"
                value={(x) => setIncomeForm({ ...incomeForm, income: x })}
            />
        </div>
        <div>
            <BaseInput
                placeholder="Суммарные платежи по кредитам, ₽"
                defaultValue={creditMonthSum}
                sessionKey="creditMonthSum"
                type="onlyNumbers"
                value={(x) => setIncomeForm({ ...incomeForm, creditMonthSum: x })}
            />
        </div>
        <div>
            <BaseInput
                placeholder="Должность"
                type={"text"}
                defaultValue={employmentPosition}
                sessionKey="employmentPosition"
                value={(x) => setIncomeForm({ ...incomeForm, employmentPosition: x })}
            />

        </div>
        <div>
            <div className={s.publicWrapper}>
                <BaseCheckbox
                    inversion
                    size="small"
                    value={publicPerson}
                    onChange={handlePublicPersonChanged}
                ></BaseCheckbox>
                <p className={s.publicMain}>Я занимаю государственные должности РФ*</p>
            </div>
            <p className={s.publicSecondary}>* Назначение и освобождение которых осуществляется Президентом или Правительством РФ (публичное должностное лицо или его родственник)</p>
        </div>
        {/* <div>
            <BaseInput
                placeholder="Дополнительный доход, ₽"
                defaultValue={additionalIncome}
                sessionKey="additionalIncome"
                type="onlyNumbers"
                value={(x) => setIncomeForm({...incomeForm, additionalIncome: x})}
            />
        </div>
        <div>
            <BaseInput
                placeholder="Источник доп. дохода"
                defaultValue={additionalIncomeSource}
                sessionKey="additionalIncomeSource"
                value={(x) => setIncomeForm({...incomeForm, additionalIncomeSource: x})}
            />
        </div> */}
        <ModalPortal
            showModal={isSpecialModalOpen}
            closeModal={() => setIsSpecialModalOpen(false)}>
            <div className={s.modal}>
                <p>Вы поставили отметку, что являетесь Публичным должностным лицом.</p>
                <p>Если это действительно так, то приглашаем вас для оформления <a href={'https://vashinvestor.ru/client/'}>в один из наших офисов</a>. В случае возникновения сложностей, звоните нам по телефону <a href={'tel:88007006331'}>8&nbsp;800&nbsp;700&nbsp;63&nbsp;31</a></p>
            </div>
        </ModalPortal>
    </div>
})

export default IncomeForm

