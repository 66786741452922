export const carPhotosTemplate = [
    // {
    //     type: "car_front",
    //     baseSrc: require("img/car-1.svg"),
    //     title: "",
    //     src: null,
    //     subTitle: "Со стороны капота",
    // },
    {
        type: "car_left",
        baseSrc: require("img/car-3.svg"),
        title: "",
        src: null,
        subTitle: "Левая сторона с капотом",
        tooltip: [
            {
                type: "right",
                text: "Видна боковая сторона"
            },
            {
                type: "right",
                text: 'Виден номер'
            },
            {
                type: "image",
                src: require('./img/tooltip-image-car_left.webp'),
                height: 134,
            }
        ]
    },
    {
        type: "car_right",
        baseSrc: require("img/car-4.svg"),
        title: "",
        src: null,
        subTitle: "Правая сторона с капотом",
        tooltip: [
            {
                type: "right",
                text: "Видна боковая сторона"
            },
            {
                type: "right",
                text: 'Виден номер'
            },
            {
                type: "image",
                src: require('./img/tooltip-image-car_right.webp'),
                height: 134,
            }
        ]
    },
    {
        type: "car_back",
        baseSrc: require("img/car-2.svg"),
        title: "",
        src: null,
        subTitle: "Задняя сторона",
        tooltip: [
            {
                type: "right",
                text: "Видна задняя сторона"
            },
            {
                type: "right",
                text: 'Виден номер'
            },
            {
                type: "image",
                src: require('./img/tooltip-image-car_back.webp'),
                height: 134,
            }
        ]
        
    },
    {
        type: "car_selfie",
        baseSrc: require("img/car-5.svg"),
        title: "",
        src: null,
        subTitle: "Ваше фото с авто, чтобы был виден номер",
        tooltip: [
            {
                type: "right",
                text: "Виден номер авто"
            },
            {
                type: "right",
                text: 'Видно ваше лицо'
            },
            {
                type: "image",
                src: require('./img/tooltip-image-car_selfie.webp'),
                height: 134,
            }
        ]
    },
    {
        type: "car_vin",
        baseSrc: require("img/car-6.svg"),
        title: "",
        src: null,
        subTitle: "Номер VIN (на металле)",
        forceTooltip: true,
        tooltip: [
            {
                type: "right",
                text: "Необходимо фото VIN на маркировочной табличке или металле кузова"
            },
            {
                type: "wrong",
                text: 'НЕ принимаются фото VIN на <span style="text-decoration: underline">лобовом</span> стекле'
            },
            {
                type: "image",
                src: require('./img/tooltip-image-car_vin.png'),
                height: 134,
            }
        ]
    },
    {
        type: "car_odometer",
        baseSrc: require("img/car-7.svg"),
        title: "",
        src: null,
        subTitle: "Фото одометра",
        tooltip: [
            {
                type: "right",
                text: "Машина заведена"
            },
            {
                type: "right",
                text: 'Данные четко видны'
            },
            {
                type: "image",
                src: require('./img/tooltip-image-car_odometr.webp'),
                height: 134,
            }
        ]
    },
]
