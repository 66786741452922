import { loanStepsArray } from "constants/onlineLoan";
import BaseButton from "form-submodule/atoms/BaseButton";
import ConfirmCodeInput from "form-submodule/atoms/ConfirmCodeInput/ConfirmCodeInput";
import ModalPortal from "form-submodule/atoms/ModalPortal";
import ErrorContainer from "form-submodule/molecules/ErrorContainer";
import OnlineLoanPhotoArray from "form-submodule/molecules/OnlineLoanPhotoArray";
import StepChangeButtons from "form-submodule/molecules/StepChangeButtons";
import {
	backReq,
	backReqV2,
	getFormattedPhone,
	getIsoDateStringWithTimezone,
} from "helpers";
import useErrorContainer from "hooks/useErrorContainer";
import useRefreshOnlineLoanDetail from "hooks/useRefreshOnlineLoan";
import CheckCircle from "img/check-circle.svg";
import React, {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useState,
} from "react";
import appStore from "store/app";
import elementsStore from "store/elementsStore";
import draftFromState from "utils/draftFromState";
import { errorsParser } from "utils/errorsParser";
import formattedMoney from "utils/formattedMoney";
import {
	eptsPhotosTemplate,
	ptsPhotosTemplate,
	stsPhotosTemplate,
} from "utils/templates/photoArrayTemplates";
import { yandexMetrikaReachGoal } from "utils/yandex-metrika";
import s from "./OnlineLoanSubject.module.scss";
import PtsForm from "./PtsForm";

import SubjectForm from "./SubjectForm";
import { carPhotosTemplate } from "./carPhotosTemplate";

const RegistrationSubject = (props, ref) => {
	const [subjectForm, setSubjectForm] = useState(null);
	const [isPts, setIsPts] = useState(true);

	const [carPhotos, setCarPhotos] = useState(carPhotosTemplate);
	const [ptsPhotos, setPtsPhotos] = useState(ptsPhotosTemplate);
	const [eptsPhotos, setEptsPhotos] = useState(eptsPhotosTemplate);
	const [stsPhotos, setStsPhotos] = useState(stsPhotosTemplate);

	const [isSubjectFormError, setIsSubjectFormError, subjectFormContainerRef] =
		useErrorContainer();
	const [isDocsPhotoError, setIsDocsPhotoError, docsPhotoContainerRef] =
		useErrorContainer();
	const [isCarPhotoError, setIsCarPhotoError, carPhotoContainerRef] =
		useErrorContainer();

	const [modal, setModal] = useState(false);
	const [congratulationModal, setCongratulationModalModal] = useState(false);

	useRefreshOnlineLoanDetail(insertData);

	async function callback() {
		if (await validate()) {
			await sendData();
			await makeRequest();
		}
	}

	async function makeRequest() {
		const response = await elementsStore.callSmsAction(() =>
			backReq("get_code", {
				id: appStore.loanDetail.id,
			}),
		);
		if (response.errors) {
			elementsStore.showSnackbar(errorsParser(response.errors));
			return;
		}
		setModal(true);
	}

	async function insertData() {
		const loan = appStore?.loanDetail ?? {};
		const draft = draftFromState(loan);

		setSubjectForm({
			...loan,
			carMark: draft.carMark || loan.carMark,
			carModel: draft.carModel || loan.carModel,
			releaseYear: draft.releaseYear || loan.releaseYear,
			type: draft.type || loan.type,
			colorBody: draft.colorBody || loan.colorBody,
			vin: draft.vin || loan.vin,
			regNum: draft.regNum || loan.regNum,
			ptsNumber: draft.ptsNumber || loan.ptsNumber,
		});
	}

	const isPhotoLoaded = (photoArray) => {
		return photoArray.every((item) => {
			if (item.type === "e_pts_addition") return true;
			return item.src;
		});
	};

	async function validate() {
		if (_isSubjectFormInvalid()) {
			setIsSubjectFormError(true);
			elementsStore.showSnackbar(errorsParser("Заполните все поля автомобиля"));
			return false;
		}
		if (isPts && subjectForm?.ptsNumber?.length !== 10) {
			setIsSubjectFormError(true);
			elementsStore.showSnackbar(errorsParser("ПТС или ЭПТС введен неверно"));
			return false;
		}
		if (!isPts && subjectForm?.ptsNumber?.length !== 15) {
			setIsSubjectFormError(true);
			elementsStore.showSnackbar(errorsParser("ПТС или ЭПТС введен неверно"));
			return false;
		}
		if (isPts && !isPhotoLoaded(ptsPhotos)) {
			setIsDocsPhotoError(true);
			elementsStore.showSnackbar(errorsParser("Загрузите фото ПТС"));
			return false;
		}
		if (!isPts && !isPhotoLoaded(eptsPhotos)) {
			setIsDocsPhotoError(true);
			elementsStore.showSnackbar(errorsParser("Загрузите фото ЕПТС"));
			return false;
		}
		if (!isPhotoLoaded(stsPhotos)) {
			setIsDocsPhotoError(true);
			elementsStore.showSnackbar(errorsParser("Загрузите фото СТС"));
			return false;
		}
		if (!isPhotoLoaded(carPhotos)) {
			setIsCarPhotoError(true);
			elementsStore.showSnackbar(errorsParser("Загрузите фото автомобиля"));
			return false;
		}
		return true;
	}

	const _isSubjectFormInvalid = () => {
		const {
			carMark,
			carModel,
			releaseYear,
			type,
			colorBody,
			regNum,
			ptsNumber,
		} = subjectForm;
		const subjectFieldsArray = [
			carMark,
			carModel,
			releaseYear,
			type,
			colorBody,
			regNum,
			ptsNumber,
		];
		return subjectFieldsArray.some(
			(field) => !field || !field.toString().trim(),
		);
	};

	async function sendData() {
		try {
			const id = appStore?.loanDetail?.id ?? null;
			return await backReqV2("loan_subject", {
				id,
				carMark: subjectForm.carMark?.value || subjectForm.carMark,
				carModel: subjectForm.carModel,
				releaseYear: subjectForm.releaseYear,
				type: subjectForm.type,
				colorBody: subjectForm.colorBody,
				engineVolume: subjectForm.engineVolume,
				vin: subjectForm.vin,
				bodyNumber: subjectForm.vin,
				regNum: subjectForm.regNum,

				ptsNumber: isPts ? subjectForm.ptsNumber : "",
				eptsNumber: !isPts ? subjectForm.ptsNumber : "",
			});
		} catch (e) {
			console.warn("err", e);
			return { errors: e.message };
		}
	}

	const completeOnlineLoan = async () => {
		elementsStore.setCheckingSmsCode(true);
		const isoDateStringWithTimezone = getIsoDateStringWithTimezone();
		try {
			const response = await backReq("send_order", {
				code: elementsStore.smsCode,
				id: appStore.loanDetail.id,
				promoAgreement: appStore.loanDetail.promoAgreement,
				electronicAgreementTimestamp: isoDateStringWithTimezone,
			});
			if (response.errors) {
				elementsStore.showSnackbar(errorsParser(response.errors));
				return false;
			}
			elementsStore.setSmsCodeConfirmed(true);
			setTimeout(() => {
				yandexMetrikaReachGoal("online_final");
				setCongratulationModalModal(true);
				elementsStore.smsInputRestoreDefault();
			}, 300);
		} catch (e) {
			console.warn(e);
		}
	};

	const onCongratulationModalClose = () => {
		localStorage.removeItem("loanId");
		localStorage.removeItem("sessionToken");
		setCongratulationModalModal(false);
		appStore.setIsLoanModalVisible(false);
		appStore.setDefaultLoanDetail();
		appStore.setCurrentStep(1, true);
	};

	useEffect(() => {
		setIsPts(/[\p{Alpha}]/gu.test(subjectForm?.ptsNumber));
		// eslint-disable-next-line
	}, [subjectForm?.ptsNumber]);

	useImperativeHandle(ref, () => ({
		validate,
		sendData,
		current: loanStepsArray[4],
	}));

	return (
		<div>
			<ErrorContainer
				innerRef={subjectFormContainerRef}
				hasError={isSubjectFormError}
			>
				<div className={s.titleWrapper}>
					<div className={s.title}>Данные ПТС / ЭПТС</div>
				</div>
				{subjectForm && (
					<PtsForm ptsForm={subjectForm} setPtsForm={setSubjectForm} />
				)}
				<hr className={s.divider} />
				<div className={s.titleWrapper}>
					<div className={s.title}>Данные СТС</div>
				</div>
				{subjectForm && (
					<SubjectForm
						subjectForm={subjectForm}
						setSubjectForm={setSubjectForm}
					/>
				)}
			</ErrorContainer>

			<ErrorContainer
				innerRef={docsPhotoContainerRef}
				hasError={isDocsPhotoError}
			>
				<div className={[s.photoWrapper, s.wrapper].join(" ")}>
					{subjectForm?.ptsNumber?.length === 10 && isPts && (
						<>
							<div className={s.photoFragment}>
								<div className={s.photoText}>
									<h4>Фотографии ПТС</h4>
								</div>
								<OnlineLoanPhotoArray
									photoArray={ptsPhotos}
									updateFunction={setPtsPhotos}
								/>
							</div>
							<hr />
						</>
					)}
					{subjectForm?.ptsNumber?.length === 15 && !isPts && (
						<>
							<div className={s.photoFragment}>
								<div className={s.photoText}>
									<h4>Скриншоты ЭПТС</h4>
								</div>
								<OnlineLoanPhotoArray
									photoArray={eptsPhotos}
									updateFunction={setEptsPhotos}
								/>
							</div>
						</>
					)}
					<div className={s.photoFragment}>
						<div className={s.photoText}>
							<h4>Фотографии СТС</h4>
						</div>
						<OnlineLoanPhotoArray
							photoArray={stsPhotos}
							updateFunction={setStsPhotos}
						/>
					</div>
				</div>
			</ErrorContainer>

			<ErrorContainer
				innerRef={carPhotoContainerRef}
				hasError={isCarPhotoError}
			>
				<div className={s.title}>Финальный этап</div>
				<div className={s.infoPanel}>
					<div className={s.progressBarWrapper}>
						<div className={s.progressBar}>
							<span>95%</span>
						</div>
						<img src={CheckCircle} alt="" />
					</div>
					<p>
						Вам предварительно одобрен займ! Для отправки заявки загрузите
						фотографии.
					</p>
					<p>
						<b>
							Если у вас нет фотографий, то вы можете закрыть сайт и вернуться
							позже. Все данные сохраняются 24 часа!
						</b>
					</p>
				</div>
				<div className={s.wrapper}>
					<div className={s.photoWrapperAuto}>
						<OnlineLoanPhotoArray
							photoArray={carPhotos}
							updateFunction={setCarPhotos}
						/>
					</div>
				</div>
			</ErrorContainer>

			<StepChangeButtons callback={callback} />

			<ModalPortal
				title="Подписание заявки"
				showModal={modal}
				closeModal={() => setModal(false)}
			>
				<div>
					<div className={s.final__modal}>
						Для отправки заявки вам необходимо подписать ее простой электронной
						подписью. Для этого вам необходимо ввести код из SMS, который
						отправлен на телефон{" "}
						{appStore?.loanDetail?.phone ? (
							<span style={{ whiteSpace: "nowrap" }}>
								{getFormattedPhone(appStore?.loanDetail?.phone)}.
							</span>
						) : (
							"указанный в заявке"
						)}
					</div>
					<ConfirmCodeInput
						onEntered={completeOnlineLoan}
						onSend={makeRequest}
					/>
				</div>
			</ModalPortal>
			<ModalPortal
				title={"Поздравляем"}
				showModal={congratulationModal}
				closeModal={onCongratulationModalClose}
			>
				<div>
					<p className={s.modalText}>
						Заявка на займ{" "}
						{appStore.loanDetail?.desiredAmount
							? `${formattedMoney(appStore.loanDetail?.desiredAmount)} рублей`
							: null}{" "}
						успешно оправлена. В ближайшее время мы ее рассмотрим и свяжемся с
						Вами
					</p>
					<BaseButton text={"Отлично!"} onClick={onCongratulationModalClose} />
				</div>
			</ModalPortal>
		</div>
	);
};

export default forwardRef(RegistrationSubject);
