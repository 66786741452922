import React from "react";

import s from "./OnlineLoanPersonal.module.scss";

import DadataInput from "form-submodule/atoms/DadataInput";
import BaseInput from "form-submodule/atoms/BaseInput";
import BaseCheckbox from "form-submodule/atoms/BaseCheckbox";
import NativeSelect from "form-submodule/molecules/NativeSelect";
import DateInput from "form-submodule/atoms/DateInput";
import BaseMessage from "form-submodule/atoms/BaseMessage";
// import LockedInput from "form-submodule/atoms/BaseInput/LockedInput";
import { categoryMilitaryTicketTemplate } from "utils/templates/categoryMilitaryTicketTemplate";
import { isMilitaryTicketTemplate } from "utils/templates/isMilitaryTicketTemplate";
import BaseSelect from "form-submodule/atoms/BaseSelect";
// import { getFormattedPhone } from "helpers";
// import draftFromState from "utils/draftFromState";
// import onSuggestion from "utils/onSuggestion";

const genderTemplate = ['Мужской', 'Женский']
const PersonalForm = ({ personalForm, setPersonalForm, disabled = false, }) => {
    const {
        surname = '',
        name = '',
        patronymic = '',
        prevSurname = '',
        prevName = '',
        prevPatronymic = '',
        nameChanged = false,
        gender = '',
        birthDate = '',
        birthPlace = '',
        // citizenship = '',
        // additionalPhone = '',
        snils = '',
        // inn = '',
        // phone = '',
        // orderEmail = '',
        isMilitaryTicket = 'Нет',
        categoryMilitaryTicket = '',
    } = personalForm;

    // async function getGender(surname, name, patronymic) {
    //     const response = await onSuggestion(`${surname?.value || surname} ${name?.value || name} ${patronymic?.value || patronymic}`, 'name');
    //     const result = response?.[0]
    //     if (result?.data?.gender !== 'UNKNOWN') {
    //         setPersonalForm({ ...personalForm, gender: result?.data?.gender === 'MALE' ? 'Мужской' : 'Женский' })
    //     }
    // }

    // useEffect(() => {
    //     if (!!surname && !!name && !!patronymic) {
    //         getGender(surname, name, patronymic);
    //     }

    //     // eslint-disable-next-line
    // }, [surname, name, patronymic])

    const handleNameChanged = () => {
        sessionStorage.setItem('nameChanged', !nameChanged)
        setPersonalForm({ ...personalForm, nameChanged: !nameChanged })
    }

    return <form autoComplete={'off'} className={[s.inputsGroup, s.inputsGroup__main].join(" ")}>
        <div>
            <DadataInput
                type="surname"
                placeholder="Фамилия *"
                defaultValue={surname.value || surname}
                sessionKey="surname"
                value={v => setPersonalForm({ ...personalForm, surname: v.value || '' })}
                disabled={disabled}
                onlyLetters
            />
            {/* <BaseInput
                placeholder="Фамилия *"
                defaultValue={surname}
                sessionKey="surname"
                value={v => setPersonalForm({ ...personalForm, surname: v || '' })}
                disabled={disabled}
                onlyLetters
            /> */}
        </div>
        <div>
            <DadataInput
                type="name"
                placeholder="Имя *"
                defaultValue={name.value || name}
                sessionKey="name"
                value={v => setPersonalForm({ ...personalForm, name: v.value || '' })}
                disabled={disabled}
                onlyLetters
            />
            {/* <BaseInput
                placeholder="Имя *"
                defaultValue={name}
                sessionKey="name"
                value={v => setPersonalForm({ ...personalForm, name: v || '' })}
                disabled={disabled}
                onlyLetters
            /> */}
        </div>
        <div>
            <DadataInput
                type="patronymic"
                placeholder="Отчество *"
                defaultValue={patronymic.value || patronymic}
                sessionKey="patronymic"
                value={v => setPersonalForm({ ...personalForm, patronymic: v.value || '' })}
                disabled={disabled}
                onlyLetters
            />
            {/* <BaseInput
                placeholder="Отчество *"
                defaultValue={patronymic}
                sessionKey="patronymic"
                value={v => setPersonalForm({ ...personalForm, patronymic: v || '' })}
                disabled={disabled}
                onlyLetters
            /> */}
        </div>

        <div className={`${s.row} ${s.reduceGap}`}>
            <div className={s.checkboxWrapper}>
                <BaseCheckbox
                    inversion
                    value={!nameChanged}
                    onChange={handleNameChanged}
                    disabled={disabled}
                >
                    ФИО не менялись
                </BaseCheckbox>
                {nameChanged && <>
                    <BaseMessage type="warning" shadow={true} centered={false} close={false}>
                        Укажите прежние ФИО
                    </BaseMessage>
                </>}
            </div>
        </div>
        {nameChanged && <>
            <div>
                <DadataInput
                    type="surname"
                    placeholder="Прежняя Фамилия *"
                    defaultValue={prevSurname.value || prevSurname}
                    sessionKey="prevSurname"
                    value={v => setPersonalForm({ ...personalForm, prevSurname: v.value || '' })}
                    disabled={disabled}
                    onlyLetters
                />
                {/* <BaseInput
                    placeholder="Прежняя Фамилия *"
                    defaultValue={prevSurname}
                    sessionKey="prevSurname"
                    value={v => setPersonalForm({ ...personalForm, prevSurname: v || '' })}
                    disabled={disabled}
                    onlyLetters
                /> */}
            </div>
            <div>
                <DadataInput
                    type="name"
                    placeholder="Прежнее Имя *"
                    defaultValue={prevName.value || prevName}
                    sessionKey="prevName"
                    value={v => setPersonalForm({ ...personalForm, prevName: v.value || '' })}
                    disabled={disabled}
                    onlyLetters
                />
                {/* <BaseInput
                    placeholder="Прежнее Имя *"
                    defaultValue={prevName}
                    sessionKey="prevName"
                    value={v => setPersonalForm({ ...personalForm, prevName: v || '' })}
                    disabled={disabled}
                    onlyLetters
                /> */}
            </div>
            <div>
                <DadataInput
                    type="patronymic"
                    placeholder="Прежнее Отчество *"
                    defaultValue={prevPatronymic.value || prevPatronymic}
                    sessionKey="prevPatronymic"
                    value={v => setPersonalForm({ ...personalForm, prevPatronymic: v.value || '' })}
                    disabled={disabled}
                    onlyLetters
                />
                {/* <BaseInput
                    placeholder="Прежнее Отчество *"
                    defaultValue={prevPatronymic}
                    sessionKey="prevPatronymic"
                    value={v => setPersonalForm({ ...personalForm, prevPatronymic: v || '' })}
                    disabled={disabled}
                    onlyLetters
                /> */}
            </div>
        </>}
        <div>
            <NativeSelect
                placeholder="Пол *"
                options={genderTemplate}
                defaultValue={gender}
                sessionKey="gender"
                onSearch={null}
                onChanged={v => setPersonalForm({ ...personalForm, gender: v || '' })}
                disabled={disabled}
            />
        </div>
        <div>
            <DateInput
                placeholder="Дата рождения *"
                sessionKey="birthDate"
                defaultValue={birthDate}
                value={v => setPersonalForm({ ...personalForm, birthDate: v || '' })}
                disabled={disabled}
            />
            {/* <BaseInput
                placeholder="Дата рождения *"
                type="date"
                defaultValue={birthDate}
                sessionKey="birthDate"
                value={v => setPersonalForm({ ...personalForm, birthDate: v || '' })}
                disabled={disabled}
            /> */}
        </div>
        {/* <div>
            <LockedInput
                placeholder="Мобильный телефон"
                value={getFormattedPhone(phone)}
                disabled
            />
        </div> */}
        <div>
            <DadataInput
                type="city"
                placeholder="Город рождения *"
                defaultValue={birthPlace?.value || birthPlace}
                sessionKey="birthPlace"
                value={v => setPersonalForm({ ...personalForm, birthPlace: v.value || '' })}
                disabled={disabled}
                onlyLetters
            />
            {/* <BaseInput
                placeholder="Город рождения *"
                defaultValue={birthPlace}
                sessionKey="birthPlace"
                value={v => setPersonalForm({ ...personalForm, birthPlace: v || '' })}
                disabled={disabled}
                onlyLetters
            /> */}
        </div>
        {/* <div>
            <BaseInput
                placeholder="Гражданство *"
                defaultValue={citizenship}
                sessionKey="citizenship"
                value={v => setPersonalForm({...personalForm, citizenship: v || ''})}
                disabled={disabled}
                onlyLetters
            />
        </div> */}
        {/* <div>
            <PhoneInput
                placeholder="Дополнительный телефон"
                defaultValue={additionalPhone}
                sessionKey="additionalPhone"
                value={v => setPersonalForm({...personalForm, additionalPhone: v || ''})}
                disabled={disabled}
            />
        </div> */}
        {/* <div>
            <BaseInput
                placeholder="ИНН"
                type="onlyNumbers"
                maxlength={12}
                defaultValue={inn}
                sessionKey="inn"
                value={v => setPersonalForm({...personalForm, inn: v || ''})}
                disabled={disabled}
            />
        </div> */}
        {/* <div>
            <BaseInput
                placeholder="Email *"
                type={'email'}
                defaultValue={orderEmail}
                sessionKey="orderEmail"
                value={v => setPersonalForm({...personalForm, orderEmail: v || ''})}
                disabled={disabled}
            />
        </div> */}
        <div>
            <BaseInput
                placeholder="СНИЛС"
                type={'snils'}
                defaultValue={snils || ''}
                sessionKey="snils"
                value={v => setPersonalForm({ ...personalForm, snils: v || '' })}
                disabled={disabled}
            />
        </div>
        <div>
            <BaseSelect
                placeholder="Военный билет"
                options={isMilitaryTicketTemplate}
                withLabel={true}
                defaultValue={isMilitaryTicket}
                sessionKey="isMilitaryTicket"
                onSearch={null}
                onChanged={v => setPersonalForm({ ...personalForm, isMilitaryTicket: v.label || '' })}
                disabled={disabled}
            />
            {/* <NativeSelect
                placeholder="Военный билет"
                options={isMilitaryTicketTemplate}
                withLabel={true}
                defaultValue={isMilitaryTicket?.label || isMilitaryTicket}
                sessionKey="isMilitaryTicket"
                onSearch={null}
                onChanged={v => setPersonalForm({ ...personalForm, isMilitaryTicket: v.label || '' })}
                disabled={disabled}
            /> */}
        </div>
        <div>
            <BaseSelect
                placeholder="Ваш призывной разряд"
                options={categoryMilitaryTicketTemplate}
                withLabel={true}
                defaultValue={
                    isMilitaryTicket === 'Есть' ?
                        categoryMilitaryTicketTemplate.find(c => c.label === categoryMilitaryTicket)?.label :
                        ''
                }
                sessionKey="categoryMilitaryTicket"
                onSearch={null}
                onChanged={v => setPersonalForm({ ...personalForm, categoryMilitaryTicket: v.label || '' })}
                disabled={disabled || isMilitaryTicket === 'Нет'}
            />
            {/* <NativeSelect
                placeholder="Ваш призывной разряд"
                options={categoryMilitaryTicketTemplate}
                withLabel={true}
                defaultValue={
                    isMilitaryTicket === 'Есть' || isMilitaryTicket?.label === 'Есть' ?
                        categoryMilitaryTicket?.label || categoryMilitaryTicket :
                        ''
                }
                sessionKey="categoryMilitaryTicket"
                onSearch={null}
                onChanged={v => setPersonalForm({ ...personalForm, categoryMilitaryTicket: v.label || '' })}
                disabled={disabled || isMilitaryTicket === 'Нет' || isMilitaryTicket?.label === 'Нет'}
            /> */}
        </div>
    </form>
}
export default PersonalForm
