import React from "react";
import s from "./NoLoanSection.module.scss";
import Hourglass from "img/hourglass.png";
import {VI_OFFICE_PHONE, VI_OFFICE_PHONE_URL} from "constants/phones";


const NoLoanSection = () => {
  return <div className={s.noLoanSection}>
    <div className={s.card}>
      <img className={s.hourglassImg} src={Hourglass} alt="hourglass"/>
      <h2 className={s.title}>Оформление онлайн-займа временно недоступно</h2>
      <p className={s.description}>Сейчас Вы можете оформить займ в офисе
          или задать любой интересующий вопрос по горячей линии&nbsp;
          <a className={s.link} href={VI_OFFICE_PHONE_URL}>{VI_OFFICE_PHONE}</a>
      </p>
  </div>
  </div>
}
export default NoLoanSection
