import React from 'react'
import s from './BaseButton.module.scss'

import {ReactComponent as ArrowLeft} from "./img/arrow-left.svg";
import {ReactComponent as ArrowRight} from "./img/arrow-right.svg";
import {ReactComponent as Plus} from "./img/plus.svg";
import {ReactComponent as Chevron} from "./img/chevron.svg";

class BaseButton extends React.Component {
  constructor(props) {
    super();
    this.props = props
  }
  handleClick(e) {
    e.stopPropagation()
    this.props.onClick(e)
  }

  render() {
    const {size, type, disabled, loading, btnId, text, arrowLeft, arrowRight, plus, chevron} = this.props

    const classes = [s.btn, s[type ? type : 'green']]
    const arrowRightClasses = [s.iconRight]
    classes.push(size ? s[size] : s.default)
    if (loading) {
        classes.push(s[`btn--${type === 'green-pay' ? 'loading-pay' : 'loading'}`])
        arrowRightClasses.push(s.iconRightLoading)
    }
    return (
      <button
        className={classes.join(' ')}
        disabled={ disabled || loading }
        onClick={ (e) => this.handleClick(e) }
        id={btnId}
      >
        { plus && <Plus className={s.plusLeft}/>}
        { arrowLeft && <ArrowLeft className={s.iconLeft}/>}
        { text && <span>{ text }</span> }
        { arrowRight && <ArrowRight className={arrowRightClasses.join(' ')}/>}
        { chevron && !loading && <Chevron className={s.chevron}/>}
      </button>
    )
  }
}

export default BaseButton
