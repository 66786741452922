export const baseCloudSrc = require(`img/upload-cloud.svg`)

export const eptsPhotosTemplate = [
    {
        type: "e_pts",
        baseSrc: require("img/epts-placeholder.svg"),
        title: "",
        src: null,
        placeholderWidth: 44,
        subTitle: "Скриншот 1-й страницы", 
    },
    {
        type: "e_pts_addition",
        baseSrc: require("img/epts-placeholder.svg"),
        title: "",
        src: null,
        placeholderWidth: 44,
        subTitle: "Скриншот 2-й страницы", 
    },
]
export const ptsPhotosTemplate = [
    {
        type: "pts_front",
        baseSrc: require("img/pts-outer-placeholder.svg"),
        title: "",
        src: null,
        subTitle: "Разворот спереди", 
        // tipText: "Обе страницы разворота должны быть в кадре",
        // rightSrc: require("img/pts-outer-tip-right.png"),
        // wrongSrc: require("img/pts-outer-tip-wrong.png"),
        placeholderWidth: 72,
        forceTooltip: true,
        tooltip: [
            {
                type: "right",
                text: "Необходим полный разворот спереди документа (1 и 2 стр.)"
            },
            {
                type: "wrong",
                text: '<span style="text-decoration: underline">НЕ</span> фотографируйте одну страницу'
            },
            {
                type: "image",
                src: require('img/tooltip-image-pts1.png'),
                height: 120,
            },
            {
                type: "image",
                src: require('img/tooltip-image-pts2.png'),
                height: 120,
            }
        ]
    },
    {
        type: "pts_back",
        baseSrc: require("img/pts-inner-placeholder.svg"),
        title: "",
        src: null,
        subTitle: "Разворот сзади",
        // tipText: "Обе страницы разворота должны быть в кадре",
        // rightSrc: require("img/pts-inner-tip-right.png"),
        // wrongSrc: require("img/pts-inner-tip-wrong.png"),
        placeholderWidth: 72,
        forceTooltip: true,
        tooltip: [
            {
                type: "right",
                text: "Необходим полный разворот сзади документа (1 и 2 стр.)"
            },
            {
                type: "wrong",
                text: '<span style="text-decoration: underline">НЕ</span> фотографируйте одну страницу'
            },
            {
                type: "image",
                src: require('img/tooltip-image-pts3.png'),
                height: 120,
            },
            {
                type: "image",
                src: require('img/tooltip-image-pts4.png'),
                height: 120,
            }
        ]
    },
]
export const stsPhotosTemplate = [
    {
        type: "sts_front",
        baseSrc: require("img/sts-front-placeholder.svg"),
        title: "",
        src: null,
        placeholderWidth: 44,
        subTitle: "Фото спереди",
        tooltip: [
            {
                type: "right",
                text: 'Необходимо фото <span style="text-decoration: underline">передней</span> стороны вашего СТС'
            },
            {
                type: "image",
                src: require('img/tooltip-image-sts1.png'),
                height: 120,
            },
        ]
    },
    {
        type: "sts_back",
        baseSrc: require("img/sts-back-placeholder.svg"),
        title: "",
        src: null,
        placeholderWidth: 44,
        subTitle: "Фото сзади",
        tooltip: [
            {
                type: "right",
                text: 'Необходимо фото <span style="text-decoration: underline">задней</span> стороны вашего СТС'
            },
            {
                type: "image",
                src: require('img/tooltip-image-sts2.png'),
                height: 120,
            },
        ]
    }
]

export const passportPhotosTemplate = [
    {
        type: "passport_main",
        baseSrc: baseCloudSrc,
        title: "Основной разворот паспорта (2-3 страницы)",
        disabled: true,
        src: null
    },
    {
        type: "passport_registration",
        baseSrc: baseCloudSrc,
        title: "Страница с текущим штампом вашего места регистрации (адрес прописки)",
        disabled: true,
        src: null
    },
    {
        type: "passport_additional",
        baseSrc: baseCloudSrc,
        title: "Дополнительное фото (при необходимости)",
        src: null
    },
]

export const personalPhotosTemplate = [
    {
        type: "passport_registration",
        baseSrc: require("img/passport-registration-placeholder.svg"),
        title: "Загрузить разворот прописки",
        src: null,
        tipText: "Обе страницы прописки должны быть в кадре",
        rightSrc: require("img/passport-registration-tip-right.png"),
        wrongSrc: require("img/passport-registration-tip-wrong.png"),
    },
]
export const passportTemplate = [
    {
        type: "passport_main",
        baseSrc: baseCloudSrc,
        title: "Основной разворот паспорта (2-3 страницы)",
        src: null
    },
]
