import s from 'form-submodule/atoms/BaseInput/BaseInput.module.scss'
import React from 'react'
import InputMask from "react-input-mask"

class PhoneInput extends React.Component {
  constructor(props) {
    super();
    this.props = props
    this.initialType = this.props.type
    this.state = {
      inputField: this.props.defaultValue || '',
      selectedType: null
    };
  }
  componentDidMount() {
    this.props.value(this.state.inputField)
  }
  componentDidUpdate(nextProps) {
    if (this.props.defaultValue !== nextProps.defaultValue) {
      this.setState({
        inputField: this.props.defaultValue
      })
    }
  }
  handleChange(value) {
    this.setState({
      selectedType: this.state.selectedType,
      inputField: value
    })
    this.props.value(value)

    if (this.props.sessionKey) {
      sessionStorage.setItem(this.props.sessionKey, value)
    }
  }
  onFocus(e) {
    if (this.props.onFocus) {
      this.props.onFocus(e)
    }
  }
  onBlur(e) {
    if (this.props.onBlur) {
      this.props.onBlur(e)
    }
  }
  render() {
    const classes = [s.inputWrapper]
    if (this.state.inputField) classes.push(s.activePlaceholder)
    return (
      <div className={ classes.join(' ') } style={ this.props.style }>
        <div className={s.placeholder}>{this.props.placeholder}</div>
        <InputMask
          mask={this.props.mask ? this.props.mask : "+7 (999) 999-99-99"}
          onChange={ (e) => this.handleChange(e.target.value) }
          style={ {height: this.props.height, fontSize: this.props.fontSize } }
          className={s.input}
          value={ this.state.inputField }
          onFocus={(e) => this.onFocus(e)}
          onBlur={(e) => this.onBlur(e)}
          autoComplete="off"
          type="tel"
          required={this.props.required ?? false}
          disabled={this.props.disabled ?? false}
        />
      </div>
    )
  }
}

export default PhoneInput
