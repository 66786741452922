import { loanStepsArray } from "constants/onlineLoan";
import PhotoPreviewUpload from "form-submodule/atoms/PhotoPreviewUpload";
import ErrorContainer from "form-submodule/molecules/ErrorContainer";
import OnlineLoanPhotoArray from "form-submodule/molecules/OnlineLoanPhotoArray";
import StepChangeButtons from "form-submodule/molecules/StepChangeButtons";
import { nextStepHandler } from "form-submodule/steps/helpers";
import { backReq, backReqV2 } from "helpers";
import useErrorContainer from "hooks/useErrorContainer";
import useRefreshOnlineLoanDetail from "hooks/useRefreshOnlineLoan";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import appStore from "store/app";
import elementsStore from "store/elementsStore";
import { errorsParser } from "utils/errorsParser";
import { loadImageSrc, uploadOnlineLoanPhoto } from "utils/imageLoader";
import { personalPhotosTemplate } from "utils/templates/photoArrayTemplates";
import { isItemSrcEmpty } from "utils/validators";
import Idx from "./Idx";
import s from "./RepeaterConfirmation.module.scss";

const PASSPORT_MAIN = "passport_main";
const passportMainBaseSrc = require("img/passport-main-placeholder.svg");
const passportMainTipRight = require("img/passport-main-tip-right.png");
const passportMainTipWrong = require("img/passport-main-tip-wrong.png");

const RepeaterConfirmation = observer(() => {
	const [personalPhotos, setPersonalPhotos] = useState(personalPhotosTemplate);
	const [passportMainSrc, setPassportMainSrc] = useState("");
	const [passportMainId, setPassportMainId] = useState(null);
	const [errorText, setErrorText] = useState("");
	const [passportIsValid, setPassportIsValid] = useState(true);

	const [
		isConfirmationError,
		setIsConfirmationError,
		confirmationContainerRef,
	] = useErrorContainer();
	const [isIdxContainerError, setIsIdxContainerError, idxContainerRef] =
		useErrorContainer();

	const addFile = async (event) => {
		const loanId = appStore.loanDetail.id;
		try {
			const { blobURL, id } = await uploadOnlineLoanPhoto(
				PASSPORT_MAIN,
				event.target.files[0],
			);
			const { data } = await backReq("get_passport_check_result", {
				id: loanId,
			});
			const { docType, passportCheck } = data;
			if (passportCheck) {
				setPassportMainSrc(blobURL);
				setErrorText("");
				setPassportMainId(id);
			} else {
				console.log({ docType });
				switch (docType) {
					case "not_document":
						setErrorText(
							"К сожалению документ не подошел по требованиям. Пожалуйста, загрузите корректный документ.",
						);
						break;
					case "passport_main":
						setErrorText("Документ должно быть видно на весь экран");
						break;
					default:
						setPassportIsValid(false);
						setErrorText(
							"Похоже ваш паспорт не гражданина РФ. Если вы являетесь гражданином другой страны, пожалуйста, продолжите оформление у нас в офисе",
						);
						break;
				}
				setPassportMainSrc("");
				// сразу удаляем
				await backReq("delete_photo", {
					id: loanId,
					photoId: id,
				});
			}
		} catch (e) {
			// elementsStore.showSnackbar(errorsParser(e.data ?? e.message));
			elementsStore.showSnackbar(
				errorsParser("Не удалось загрузить фотографию, попробуйте чуть позже"),
			);
		}
	};

	const removePhoto = async () => {
		const sendResponse = await backReq("delete_photo", {
			id: appStore.loanDetail.id,
			photoId: passportMainId,
		});
		if (sendResponse.errors) {
			elementsStore.showSnackbar(errorsParser(sendResponse.errors));
		} else {
			setPassportMainSrc("");
			setPassportMainId(null);
		}
	};

	useEffect(() => {
		async function loadPhoto() {
			const { photos, id } = appStore.loanDetail;
			if (!photos) {
				return;
			}
			const mainPassportInfo = photos.find(
				(item) => item.type === PASSPORT_MAIN,
			);
			if (!mainPassportInfo) return;
			const { data } = await backReq("get_passport_check_result", { id });
			// livelinessCheck, passportCheck
			const { docType, passportCheck } = data;
			if (passportCheck) {
				const blobUrl = await loadImageSrc(mainPassportInfo.url);
				setPassportMainId(mainPassportInfo.id);
				setPassportMainSrc(blobUrl);
			} else {
				switch (docType) {
					case "not_document":
						setErrorText(
							"К сожалению документ не подошел по требованиям. Пожалуйста, загрузите корректный документ.",
						);
						break;
					case "passport_main":
						setErrorText("Документ должно быть видно на весь экран");
						break;
					default:
						setPassportIsValid(false);
						setErrorText(
							"Похоже ваш паспорт не гражданина РФ. Если вы являетесь гражданином другой страны, пожалуйста, продолжите оформление у нас в офисе",
						);
						break;
				}
			}
		}
		loadPhoto();
	}, []);

	useRefreshOnlineLoanDetail();

	const validate = async () => {
		if (personalPhotos.some(isItemSrcEmpty) || !passportMainSrc) {
			setIsConfirmationError(true);
			elementsStore.showSnackbar(errorsParser("Загрузите фото паспорта"));
			return false;
		}
		const loanId = appStore.loanDetail.id;
		const { data } = await backReq("get_liveliness_check_result", {
			id: loanId,
		});
		const { livelinessCheck } = data;
		if (!livelinessCheck) {
			setIsIdxContainerError(true);
			elementsStore.showSnackbar(errorsParser("Загрузите селфи"));
			return false;
		}
		const validateStepResponse = await backReqV2("personal_confirm", {
			id: loanId,
		});
		if (validateStepResponse.errors) {
			elementsStore.showSnackbar(errorsParser(validateStepResponse.errors));
			return false;
		}
		return true;
	};

	return (
		<div>
			<ErrorContainer
				innerRef={confirmationContainerRef}
				hasError={isConfirmationError}
			>
				<div className={s.title}>Фотография паспорта</div>
				<div className={s.confirmation}>
					<PhotoPreviewUpload
						src={passportMainSrc}
						baseSrc={passportMainBaseSrc}
						title={"Загрузить основной разворот"}
						disabled={false}
						errorText={errorText}
						onLoad={addFile}
						onDelete={removePhoto}
						tipText={"Документ должен быть крупный и чёткий"}
						rightSrc={passportMainTipRight}
						wrongSrc={passportMainTipWrong}
					/>
					<OnlineLoanPhotoArray
						photoArray={personalPhotos}
						updateFunction={setPersonalPhotos}
					/>
				</div>
			</ErrorContainer>
			<ErrorContainer innerRef={idxContainerRef} hasError={isIdxContainerError}>
				<div className={s.title}>Фотография лица</div>
				<p className={s.idxDescription}>
					Пройдите биометрическую проверку лица. От вас потребуется включить
					камеру и выполнить указанные действия. Для начала нажмите "Начать
					проверку"
				</p>
				<div className={s.idxWrapper}>
					<Idx />
				</div>
			</ErrorContainer>
			<StepChangeButtons
				specialDisabled={!passportIsValid}
				callback={nextStepHandler(validate, null, loanStepsArray[2])}
			/>
		</div>
	);
});

export default RepeaterConfirmation;
