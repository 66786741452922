import BaseCheckbox from "form-submodule/atoms/BaseCheckbox";
import BaseInput from "form-submodule/atoms/BaseInput";
import BaseSelect from "form-submodule/atoms/BaseSelect";
import DadataInput from "form-submodule/atoms/DadataInput";
import DateInput from "form-submodule/atoms/DateInput";
import React, { useEffect } from "react";
import { maritalStatusTemplate } from "utils/templates/martialStatusTemplate";
import s from "./OnlineLoanPersonal.module.scss";

const PassportForm = ({
	passportForm,
	setPassportForm,
	disabled = false,
	openChangeInfoWarningModal,
}) => {
	const {
		passportSeries,
		passportNumber,
		passportIssuedBy,
		passportDate,
		divisionCode,
		registration,
		registrationIsActual,
		actualRegistration,
		maritalStatus,
		registrationFlat,
		registrationFlatIsAbsent,
		actualRegistrationFlat,
		actualRegistrationFlatIsAbsent,
	} = passportForm;

	useEffect(() => {
		if (registrationFlatIsAbsent) {
			setPassportForm({ ...passportForm, registrationFlat: "" });
		}
		// eslint-disable-next-line
	}, [registrationFlatIsAbsent]);

	useEffect(() => {
		if (actualRegistrationFlatIsAbsent) {
			setPassportForm({ ...passportForm, actualRegistrationFlat: "" });
		}
		// eslint-disable-next-line
	}, [actualRegistrationFlatIsAbsent]);

	const handleRegistrationIsActualChanged = () => {
		sessionStorage.setItem("registrationIsActual", !registrationIsActual);
		setPassportForm({
			...passportForm,
			registrationIsActual: !registrationIsActual,
		});
	};

	const handleRegistrationFlatIsAbsentChanged = () => {
		sessionStorage.setItem(
			"registrationFlatIsAbsent",
			!registrationFlatIsAbsent,
		);
		setPassportForm({
			...passportForm,
			registrationFlatIsAbsent: !registrationFlatIsAbsent,
		});
	};

	const handleActualuRegistrationFlatIsAbsentChanged = () => {
		sessionStorage.setItem(
			"actualRegistrationFlatIsAbsent",
			!actualRegistrationFlatIsAbsent,
		);
		setPassportForm({
			...passportForm,
			actualRegistrationFlatIsAbsent: !actualRegistrationFlatIsAbsent,
		});
	};

	const handleSelectPassportIssuedBy = (v) => {
		const code = v?.data?.code;
		if (code) {
			setPassportForm({ ...passportForm, divisionCode: code });
			sessionStorage.setItem("divisionCode", code);
		}
	};

	return (
		<div className={[s.inputsGroup, s.inputsGroup__main].join(" ")}>
			<div className={s.inputsGroup__codeAndNumber}>
				<div onClick={openChangeInfoWarningModal}>
					<BaseInput
						type={"passportSeries"}
						placeholder="Серия *"
						defaultValue={passportSeries}
						sessionKey="passportSeries"
						value={(v) =>
							setPassportForm({ ...passportForm, passportSeries: v })
						}
						disabled={disabled}
					/>
				</div>
				<div onClick={openChangeInfoWarningModal}>
					<BaseInput
						type="onlyNumbers"
						placeholder="Номер *"
						defaultValue={passportNumber}
						sessionKey="passportNumber"
						maxlength={6}
						value={(v) =>
							setPassportForm({ ...passportForm, passportNumber: v })
						}
						disabled={disabled}
					/>
				</div>
			</div>
			<div>
				<DateInput
					placeholder="Дата выдачи *"
					sessionKey="passportDate"
					defaultValue={passportDate}
					value={(v) => setPassportForm({ ...passportForm, passportDate: v })}
					disabled={disabled}
				/>
			</div>
			<div>
				<BaseInput
					placeholder="Код подразделения *"
					type={"unitCode"}
					defaultValue={divisionCode}
					sessionKey="divisionCode"
					value={(v) => setPassportForm({ ...passportForm, divisionCode: v })}
					disabled={disabled}
				/>
			</div>
			<div>
				<DadataInput
					placeholder="Кем выдан *"
					type={"fmsUnit"}
					defaultValue={passportIssuedBy}
					sessionKey="passportIssuedBy"
					value={(v) =>
						setPassportForm({ ...passportForm, passportIssuedBy: v.value })
					}
					query={divisionCode}
					onSelect={handleSelectPassportIssuedBy}
					disabled={disabled}
				/>
			</div>
			<div>
				<BaseSelect
					placeholder="Семейное положение *"
					options={maritalStatusTemplate}
					withLabel={true}
					defaultValue={
						maritalStatusTemplate.find((c) => c.label === maritalStatus)
							?.label || ""
					}
					sessionKey="maritalStatus"
					onSearch={null}
					onChanged={(v) =>
						setPassportForm({ ...passportForm, maritalStatus: v.label || "" })
					}
					disabled={disabled}
				/>
			</div>
			<div className={s.wideColumn}>
				<DadataInput
					type="address"
					placeholder="Адрес регистрации (Город, улица, дом) *"
					defaultValue={registration?.value || registration}
					sessionKey="registration"
					value={(v) =>
						setPassportForm({
							...passportForm,
							registration: v.value,
							city: v.data.city ?? v.data.settlement,
						})
					}
					disabled={disabled}
					isCompany
				/>
			</div>
			<div className={s.flexColumn}>
				<div>
					<BaseInput
						placeholder="Квартира"
						type="onlyNumbers"
						defaultValue={registrationFlatIsAbsent ? "" : registrationFlat}
						sessionKey="registrationFlat"
						value={(v) =>
							setPassportForm({ ...passportForm, registrationFlat: v })
						}
						disabled={disabled || registrationFlatIsAbsent}
					/>
				</div>
				<div>
					<BaseCheckbox
						value={registrationFlatIsAbsent}
						onChange={handleRegistrationFlatIsAbsentChanged}
						disabled={disabled}
						inversion
					>
						Нет квартиры
					</BaseCheckbox>
				</div>
			</div>
			<div className={s.wideColumn}>
				<BaseCheckbox
					value={registrationIsActual}
					onChange={handleRegistrationIsActualChanged}
					disabled={disabled}
					inversion
				>
					Совпадает с фактическим адресом проживания
				</BaseCheckbox>
			</div>
			{!registrationIsActual && (
				<>
					<div className={s.wideColumn}>
						<DadataInput
							type="address"
							placeholder="Адрес фактического проживания (Город, улица, дом) *"
							defaultValue={actualRegistration?.value || actualRegistration}
							sessionKey="actualRegistration"
							value={(v) =>
								setPassportForm({
									...passportForm,
									actualRegistration: v.value,
									actualCity: v.data.city ?? v.data.settlement,
								})
							}
							disabled={disabled}
							isCompany
						/>
					</div>
					<div className={s.flexColumn}>
						<div>
							<BaseInput
								placeholder="Квартира"
								type="onlyNumbers"
								defaultValue={
									actualRegistrationFlatIsAbsent ? "" : actualRegistrationFlat
								}
								sessionKey="actualRegistrationFlat"
								value={(v) =>
									setPassportForm({
										...passportForm,
										actualRegistrationFlat: v,
									})
								}
								disabled={disabled || actualRegistrationFlatIsAbsent}
							/>
						</div>
						<div>
							<BaseCheckbox
								value={actualRegistrationFlatIsAbsent}
								onChange={handleActualuRegistrationFlatIsAbsentChanged}
								disabled={disabled}
								inversion
							>
								Нет квартиры
							</BaseCheckbox>
						</div>
					</div>
				</>
			)}
		</div>
	);
};
export default PassportForm;
