import React from "react";
import s from './AboutCompany.module.scss';
import ShieldImg from './img/shield.svg'
import GlobeImg from './img/globe.svg'
import HeartImg from './img/heart.svg'
import OfficeImg from './img/officePhoto.jpg'
import FeatureImg from './img/featurePhoto.jpg'

const cards = [
    {
        feature: 'с нами безопасно',
        featureIcon: ShieldImg,
        title: <h5>Одобрено ЦБ РФ</h5>,
        description: <p>мы работаем только в законодательном поле и состоим в государственном реестре ЦБ РФ. <a className={s.link} href="https://vashinvestor.ru/media/file/%D0%A1%D0%B2%D0%B8%D0%B4%D0%B5%D1%82%D0%B5%D0%BB%D1%8C%D1%81%D1%82%D0%B2%D0%BE_%D0%A6%D0%91_%D0%9C%D0%9A%D0%9A_%D0%A6%D0%A4%D0%A0_%D0%92%D0%98_1_1.pdf" target="_blank" rel="noopener noreferrer">Документ</a></p>
    },
    {
        feature: 'мы — федеральная сеть',
        featureIcon: GlobeImg,
        title: <h5>Мы в 24 городах</h5>,
        description: <p>работаем с 2013 года, имеем офисы <a className={s.link} href="https://vashinvestor.ru/client/" target="_blank" rel="noopener noreferrer">в 24 городах</a> и выдаём займы онлайн по всей стране</p>
    },
    {
        feature: 'мы — выручаем',
        featureIcon: HeartImg,
        title: <h5>13 500+ клиентов</h5>,
        description: <p>нам доверяют более 13 500 постоянных клиентов по всей России</p>
    },
]

const AboutCompany = () => {


    return <section className={s.aboutCompanySection}>
        <div className={'container'}>
            <h2 className={s.title}>О компании</h2>

            <div className={s.content}>
                <div className={s.officeImg} style={{
                    backgroundImage: `url(${OfficeImg})`
                }}>
                    <p>Современные офисы для комфортного обслуживания по всей России</p>
                </div>

                <div className={s.cardsContainer}>
                    {cards.map((item, index) => (
                        <div className={s.card} key={`feature-card-${index}`}>
                            <div className={s.cardFeature}>
                                <div className={s.iconWrapper}>
                                    <img src={item.featureIcon} alt="" />
                                </div>
                                <span>{item.feature}</span>
                            </div>
                            <div className={s.cardContent}>
                                {item.title}
                                {item.description}
                            </div>
                        </div>
                    ))}

                    <div className={s.imageCard} style={{
                        backgroundImage: `url(${FeatureImg})`
                    }}></div>
                </div>
            </div>

            <h2 className={s.title}>Видео о нас</h2>
            <div className={s.videoResponsive}>
                <video
                    controls
                    title="Как проходит получение займа в МФО &quot;Ваш инвестор&quot;"
                    poster="https://onlineformer.vashinvestor.ru/media-lk/preview.jpg"
                >
                    <source
                        src="https://onlineformer.vashinvestor.ru/media-lk/test.mp4"
                        type="video/mp4" />
                </video>
                {/* <iframe
                    width="853"
                    height="480"
                    src={`https://www.youtube.com/embed/6BvxY-XMW0Q`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                    title="Как проходит получение займа в МФО &quot;Ваш инвестор&quot;"
                /> */}
            </div>
        </div>
    </section>
}

export default AboutCompany
