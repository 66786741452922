import React from "react";
import s from "./CalculatorSection.module.scss";
import Calculator from "form-submodule/organisms/Calculator";
import Tags from "./Tags";
import Guarantee from "img/guarantee.png"

const LandingCalculator = ({ referCode }) => {

    return <section className={s.calculatorSection} id={'calculator'}>
        <div className={'container'}>
            <div className={s.sectionWrapper}>
                <div className={s.bannerCalc}>
                    <div className={s.textWrapper}>
                        <span className={s.info}>
                            <svg className={s.round} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 8" fill="none">
                                <circle cx="4" cy="4" r="4" fill="#4CAF50" />
                            </svg>
                            онлайн займ под авто
                        </span>
                        <h1 className={s.title}>Займы на&nbsp;карту под&nbsp;залог <span className={s.titleGradient}>автомобиля</span></h1>
                        <div className={`${s.tags} ${s.tagsDesktop}`}>
                            <Tags />
                            <div className={s.guarantee}>
                                <img src={Guarantee} alt="guarantee" />
                                Гарантия безопасности<br />Входим в реестр ЦБ РФ
                            </div>
                        </div>
                    </div>
                    <div className={s.calculatorCardWrapper}>
                        <div className={s.calculatorCard}>
                            <Calculator isMain={true} referCode={referCode} />
                        </div>
                        <p className={s.agreements}>Нажимая «Продолжить», я даю <a
                            href='/docs/Согласие на обработку ПДн.pdf' target="_blank" rel="noopener noreferrer">Согласие на
                            обработку персональных данных</a> на основании <a href='/docs/Политика_ООО_МФК_ЦФР_перс_данные.pdf'
                                target="_blank" rel="noopener noreferrer">Политики
                                конфиденциальности</a></p>
                    </div>
                </div>
                <div className={`${s.tags} ${s.tagsMobile}`}>
                    <Tags />
                </div>
            </div>
        </div>
    </section>;
}

export default LandingCalculator
