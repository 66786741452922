import React from "react";
import s from './Advantages.module.scss';
import Card1Img from './img/card-1.jpg'
import Card2Img from './img/card-2.jpg'
import Card3Img from './img/card-3.jpg'
import Card4Img from './img/card-4.jpg'
import Card5Img from './img/card-5.jpg'
import Card6Img from './img/card-6.jpg'

const cards = [
    {
        title: <h5>Автомобиль остаётся <span className={s.green}>у вас</span></h5>,
        description: <p>вы пользуетесь авто как и раньше, мы не забираем ПТС</p>,
        image: Card1Img
    },
    {
        title: <h5><span className={s.green}>Любая</span> кредитная история</h5>,
        description: <p>даже если другие отказывают, мы готовы рассмотреть заявку</p>,
        image: Card2Img
    },
    {
        title: <h5>Деньги — <span className={s.green}>за 17 мин</span></h5>,
        description: <p>это среднее время получения займа на счёт c момента нажатия кнопки</p>,
        image: Card3Img
    },
    {
        title: <h5><span className={s.green}>Досрочное</span> погашение займов</h5>,
        description: <p>не начисляем проценты при досрочном закрытии</p>,
        image: Card4Img
    },
    {
        title: <h5>Одобряем <span className={s.green}>95% заявок</span></h5>,
        description: <p>идём навстречу и одобряем высокий процент поступающих заявок</p>,
        image: Card5Img
    },
    {
        title: <h5>Поддерживаем <span className={s.green}>24/7</span></h5>,
        description: <p>наши специалисты поддержки готовы прийти на помощь круглосуточно</p>,
        image: Card6Img
    },
]

const Advantages = () => {
    return <section className={s.advantagesSection}>
        <div className={'container'}>
            <h2 className={s.title}>Почему выбирают нас</h2>
            <div className={s.cardsContainer}>
                {cards.map((card, index) => (
                    <div className={s.card} key={`card-${index}`}>
                        <div className={s.cardImage} style={{
                            backgroundImage: `url(${card.image})`
                        }}></div>
                        <div className={s.cardContent}>
                            {card.title}
                            {card.description}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </section>
}

export default Advantages
