import { Slider } from "antd";
import { MAX_LOAN, MIN_LOAN } from "constants/onlineLoan";
import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import appStore from "store/app";
import formattedMoney from "utils/formattedMoney";
import s from "./Calculator.module.scss";
import MoreMoneyModal from "./MoreMoneyModal";

const periods = [2, 3, 4, 24];

const baseMarkStyle = {
	fontFamily: "Muller",
	whiteSpace: "nowrap",
	marginTop: "7px",
	fontSize: "12px",
	fontWeight: "400",
	lineHeight: "18px",
	pointerEvents: "none",
};

const Sliders = observer(({ isEqual, setIsTouched }) => {
	const [periodMarks, setPeriodMarks] = useState();
	const [periodString, setPeriodString] = useState("месяца");
	const [manualAmountInput, setManualAmountInput] = useState("");
	const [inputAmountWidth, setInputAmountWidth] = useState(1);

	const [isShowMoneyModal, setIsShowMoneyModal] = useState(false);
	const { loanTerm, desiredAmount } = appStore.loanDetail;

	useEffect(() => {
		const formattedMoneyResult = formattedMoney(desiredAmount);
		setManualAmountInput(formattedMoneyResult);
		setInputAmountWidth(formattedMoneyResult.length);
	}, [desiredAmount]);

	useEffect(() => {
		if (loanTerm === 0) {
			setLoanTerm(2);
		}
		setPeriodString(getPeriodStr(loanTerm));
		// eslint-disable-next-line
	}, [loanTerm]);

	const getPeriodStr = (period) => {
		if (!period) return "месяца";
		return periods.includes(Number.parseInt(period)) ? "месяца" : "месяцев";
	};

	useEffect(() => {
		if (isEqual) {
			setPeriodMarks({
				0: {
					style: {
						transform: "translate(-6px)",
						...baseMarkStyle,
					},
					label: "2 мес",
				},
				24: {
					style: {
						transform: "translate(-30px)",
						...baseMarkStyle,
					},
					label: "24 мес",
				},
			});
		} else {
			setPeriodMarks({
				2: {
					style: {
						transform: "translate(-6px)",
						...baseMarkStyle,
					},
					label: "2 мес",
				},
				24: {
					style: {
						transform: "translate(-30px)",
						...baseMarkStyle,
					},
					label: "24 мес",
				},
			});
		}
	}, [isEqual]);

	const setDesiredAmount = (newDesiredAmount) => {
		setIsTouched(true);
		appStore.setLoanDetail({
			...appStore.loanDetail,
			desiredAmount: newDesiredAmount,
		});
	};

	const setLoanTerm = (newLoanTerm) => {
		setIsTouched(true);
		appStore.setLoanDetail({
			...appStore.loanDetail,
			loanTerm: newLoanTerm,
		});
	};

	const onManualAmountInput = (e) => {
		const newVal = e.target.value.replace(/[^\d.-]+/g, "");
		if (newVal.length > 7) return;
		setDesiredAmount(newVal);
	};

	const onManualAmountInputBlur = () => {
		if (desiredAmount < MIN_LOAN) {
			setDesiredAmount(MIN_LOAN);
		}
		if (desiredAmount > MAX_LOAN) {
			setDesiredAmount(MAX_LOAN);
		}
	};

	const onManualPeriodInput = (e) => {
		if (e.target.value) {
			setLoanTerm(Number.parseInt(e.target.value) ?? "");
		} else {
			setLoanTerm("");
		}
	};

	const onManualPeriodInputBlur = () => {
		if (!loanTerm || loanTerm < 2) {
			setLoanTerm(2);
		}
		if (loanTerm > 24) {
			setLoanTerm(24);
		}
	};

	return (
		<div className={s.main_slider}>
			<label className={s.sliderWrapper}>
				<span className={s.sliderTitle}>желаемая сумма</span>
				<div className={s.inputWrapper}>
					<input
						type="text"
						value={manualAmountInput}
						style={{
							width: `${
								(inputAmountWidth > 3
									? inputAmountWidth - 1
									: inputAmountWidth) + 0.2
							}ch`,
						}}
						onBlur={onManualAmountInputBlur}
						onChange={onManualAmountInput}
					/>
					<span>₽</span>
				</div>
				<div className={s.slider}>
					<Slider
						min={MIN_LOAN}
						max={MAX_LOAN}
						step={5000}
						onChange={setDesiredAmount}
						value={desiredAmount}
						tooltip={{ formatter: null, open: false }}
						marks={{
							30000: {
								style: {
									transform: "translate(-8px)",
									...baseMarkStyle,
								},
								label: MIN_LOAN.toLocaleString(),
							},
							1000000: {
								style: {
									transform: "translate(-54px)",
									...baseMarkStyle,
								},
								label: MAX_LOAN.toLocaleString(),
							},
						}}
					/>
				</div>
			</label>
			<label className={s.sliderWrapper}>
				<span className={s.sliderTitle}>желаемый срок</span>
				<div className={s.inputWrapper}>
					<input
						type="number"
						value={loanTerm}
						style={{ width: loanTerm >= 10 ? "1.8ch" : "1ch" }}
						onBlur={onManualPeriodInputBlur}
						onChange={onManualPeriodInput}
					/>
					<span>{periodString}</span>
				</div>
				<div className={s.slider}>
					<Slider
						min={2}
						max={24}
						step={1}
						onChange={(e) => setLoanTerm(e)}
						value={loanTerm}
						tooltip={{ formatter: null, open: false }}
						marks={periodMarks}
					/>
				</div>
			</label>
			<MoreMoneyModal
				showModal={isShowMoneyModal}
				close={() => setIsShowMoneyModal(false)}
			/>
		</div>
	);
});
export default Sliders;
