import {useEffect, useState} from 'react';
import elementsStore from "store/elementsStore";
import {errorsParser} from "utils/errorsParser";
import appStore from "store/app";


function useRefreshOnlineLoanDetail(insertDataFunc) {
    const [errors, setErrors] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        async function fetchData() {
            const id = appStore.loanDetail.id ?? localStorage.getItem('loanId') ?? false
            if (!id) return
            try {
               setIsLoading(true)
               return appStore.refreshLoanDetail(id)
            } catch (e) {
                setErrors({errors: e.message})
                throw new Error('fetch data error')
            } finally {
                setIsLoading(false)
            }
        }

        fetchData().then(insertDataFunc).catch(e => console.warn(e))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (errors) elementsStore.showSnackbar(errorsParser(errors));
    }, [errors])

    return [isLoading, errors]
}

export default useRefreshOnlineLoanDetail
