export const cardMask = (string) => {
    if(!string) return null

    return string.slice(0, 4) + '******' + string.slice(-4)
}

export const inputCardMask = (value) => {
  const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
  const matches = v.match(/\d{4,19}/g);

 return _matchHandler(matches, value, 4)

}

export const inputEptsMask = (value) => {
  const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
  const matches = v.match(/\d{5,15}/g);
  return _matchHandler(matches, value, 5)
}

export const inputSnilsMask = (value) => {
    let v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    v = v.substring(0,3)
    + (v.substring(3,6) ? ('-' + v.substring(3,6)) : '')
    + (v.substring(6,9) ? ('-' + v.substring(6,9)) : '')
    + (v.substring(9) ? (' ' + v.substring(9)) : '')
    return v.slice(0,14)

}

export const inputInnMask = (value) => {
  let v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
  v = v.substring(0,4)
      + (v.substring(4,10) ? ('-' + v.substring(4,10)) : '')
      + (v.substring(10) ? ('-' + v.substring(10)) : '')

  return v.slice(0,14)
}

export const inputUnitCodeMask = (value) => {
  const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
  const matches = v.match(/\d{3,6}/g);
  return _matchHandler(matches, value, 3, '-')
}

export const inputPassportSeries = (value) => {
  const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
  const matches = v.match(/\d{2,4}/g);
    return _matchHandler(matches, value, 2)
}

export const inputRegistrationIndex = (value) => {
  const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
  const matches = v.match(/\d{2,6}/g);
  return _matchHandler(matches, value, 2)
}

const _matchHandler = (matches, value, step=2, separator=' ') =>{
    const match = (matches && matches[0]) || '';
    const parts = [];

    for (let i=0, len=match.length; i<len; i+=step) {
        parts.push(match.substring(i, i+step))
    }
    if (parts.length) {
        return parts.join(separator)
    } else {
        return value
    }
}
