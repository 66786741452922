import React from 'react'

import s from './Footer.module.scss'

import BaseButton from "form-submodule/atoms/BaseButton";
import appStore from "store/app";
import {VI_OFFICE_PHONE, VI_OFFICE_PHONE_URL} from "constants/phones";

const Footer = () => {

    const openCallbackModal = () => {
        appStore.setIsCallbackModalVisible(true)
    }

    return <footer className={s.footer}>
        <div className={'container'}>
            <div className={s.footerWrapper}>
                <div className={s.footerDescriptionWrapper}>
                    <p>ООО МФК «ЦФР ВИ», ИНН 5407967714, ОГРН 1175476112646. Регистрационный номер в государственном
                        реестре микрофинансовых организаций: № 1803550008909 с 13.07.2021.
                        Юридический адрес: г. Новосибирск, ул. Фабричная, 55, оф. 408. Почтовый адрес: 630007, а/я 151.
                        Email: info@vashinvestor.ru
                    </p>
                    <a href='/docs/Соглашение_об_электронном_взаимодействии_10_03_2023.pdf' target={'_blank'}>
                        Соглашение об электронном взаимодействии
                    </a>
                </div>
                <div className={s.footerPhoneWrapper}>
                    <a href={VI_OFFICE_PHONE_URL} className={s.footerPhone}>{VI_OFFICE_PHONE}</a>
                    <span className={s.footerHotLine}>Горячая линия бесплатно</span>
                    <div>
                        <BaseButton
                            text='Заказать звонок'
                            onClick={openCallbackModal}
                        />
                    </div>
                </div>
            </div>
            <span className={s.footerEntity}>© «Ваш инвестор» 2018-2023</span>
        </div>

    </footer>;
}

export default Footer
