import {backReq} from "helpers";
import appStore from "store/app";
import elementsStore from "store/elementsStore";
import {errorsParser} from "utils/errorsParser";
import {loanStepsArray} from "constants/onlineLoan";

export async function validateStep(step) {
    try {
        return await backReq("validate_step", {
            step,
            id: appStore.loanDetail.id
        })
    } catch (e) {
        return {errors: e.message}
    }
}

// return errors or false
export async function checkCurrentStep(step, noRefresh = false) {
    let response
    const id = appStore?.loanDetail?.id
    if (!id) return {errors: 'no id'}
    try {
        if (appStore.loanDetail?.currentStep !== step) {
            // response = await backReq("change_step", {
            //     id,
            //     step
            // })
            // if (response.errors) {
            //     return response
            // }
            if (!noRefresh) {
                await appStore.refreshLoanDetail()
            }
            return response
        } else {
            return {}
        }
    } catch (e) {
        return {errors: e.message}
    }
}

export async function moveToNextOnlineLoanStep(step) {
    try {
        // const nextStepResponse = await backReq("change_step", {
        //     step,
        //     id: appStore.loanDetail.id
        // })
        // if (nextStepResponse.errors) {
        //     elementsStore.showSnackbar(errorsParser(nextStepResponse.errors))
        //     return
        // }
        const currentStepIndex = loanStepsArray.findIndex(s => s === step)
        await appStore.setCurrentStep(currentStepIndex, true)
        // return nextStepResponse
        return {}
    } catch (e) {
        return {errors: e.message}
    }
}

export const nextStepHandler = (validationFunction, sendDataFunction, currentStep) => async () => {
    try {
        elementsStore.setIsChangeStepButtonLoading(true)
        if (typeof validationFunction === 'function') {
            const isValid = await validationFunction()
            if (!isValid) {
                return
            }
        }
        const id = appStore?.loanDetail?.id
        if (!id) {
            console.log('no id')
            return {errors: 'no id'}
        }

        // if (appStore.loanDetail?.currentStep !== currentStep) {
        //     const response = await backReq("change_step", {
        //         id,
        //         step: currentStep
        //     })
        //     if (response.errors) {
        //         elementsStore.showSnackbar(errorsParser(response.errors))
        //         return
        //     }
        // }
        if (typeof sendDataFunction === 'function') {
            const response = await sendDataFunction()
            if (response?.errors) {
                elementsStore.showSnackbar(errorsParser(response.errors))
                return
            }
        }
        // const currentStepIndex = loanStepsArray.findIndex(step => step === currentStep)
        // const nextStepIndex = currentStepIndex + 1 < loanStepsArray.length ? currentStepIndex + 1 : currentStepIndex
        // const nextStepResponse = await backReq("change_step", {
        //     step: loanStepsArray[nextStepIndex],
        //     id
        // })
        // if (nextStepResponse.errors) {
        //     elementsStore.showSnackbar(errorsParser(nextStepResponse.errors))
        //     return
        // }
        await appStore.setCurrentStep(appStore.currentStep + 1, true)
    } catch (e) {
        console.warn(e)
    } finally {
        elementsStore.setIsChangeStepButtonLoading(false)
    }
}

