import React, {useState} from 'react'

import BaseClose from "form-submodule/atoms/BaseClose";

import s from './BaseMessage.module.scss'

const BaseMessage = ({type, size='default', centered, shadow, close, children, html=false}) => {

    const [element, setElement] = useState(true)
    const [hide, setHide] = useState(false)

    const onClose = () => {
        setHide(true)
        setTimeout(() => {
            setElement(false)
        }, 300)
    }

    const classes = [s.message, s[`type-${type}`], s[`size-${size}`]]
    if (centered) classes.push(s.centered)
    if (shadow) classes.push(s.shadow)
    if (hide) classes.push(s.hide)

    const closeButton = close &&
        <div className={s.close} onClick={() => onClose()}>
            <BaseClose type="simple"/>
        </div>

    const icon = type && <img className={s.icon} src={require(`img/${type}.svg`)} alt='' />

    return element
        ? <div className={classes.join(' ')}>
            {closeButton}
            {icon}
            {html ?
                <div className={s.text} dangerouslySetInnerHTML={{__html: children}}/>
                :
                <div className={s.text}>{children}</div>
            }
        </div>
        : null
}

export default BaseMessage
