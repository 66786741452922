import s from "components/Footer/Footer.module.scss";
import BaseButton from "form-submodule/atoms/BaseButton";
import BaseCheckbox from "form-submodule/atoms/BaseCheckbox";
import ModalContainer from "form-submodule/atoms/ModalContainer";
import PhoneInput from "form-submodule/atoms/PhoneInput";
import { backReq } from "helpers";
import { observer } from "mobx-react";
import React, { useState } from "react";
import appStore from "store/app";
import elementsStore from "store/elementsStore";
import { errorsParser } from "utils/errorsParser";
import { trimmedPhoneWithPlus } from "utils/formattedPhone";
import { validatePhone } from "utils/validators";
import { yandexMetrikaReachGoal } from "utils/yandex-metrika";

const CallbackModal = observer(() => {
	const [isAgreed, setIsAgreed] = useState(false);
	const [phone, setPhone] = useState("");
	const [validatePhoneText, setValidatePhoneText] = useState("");
	const [step, setStep] = useState(1);

	const goToSecondStep = async () => {
		const hasError = validatePhone(phone);
		if (hasError) {
			setValidatePhoneText(hasError);
			return;
		}

		setValidatePhoneText("");

		const response = await backReq("get_call", {
			phone: trimmedPhoneWithPlus(phone),
		});

		if (response.errors) {
			elementsStore.showSnackbar(errorsParser(response.errors));
			return;
		}

		yandexMetrikaReachGoal("zakaz_zvonka");
		setStep(2);
		setTimeout(() => {
			restoreDefault();
		}, 2000);
	};

	const restoreDefault = () => {
		appStore.setIsCallbackModalVisible(false);
		setStep(1);
		setPhone("");
		setIsAgreed(false);
	};

	return (
		<ModalContainer
			title={"Заказать звонок"}
			showModal={appStore.isCallbackModalVisible}
			closeModal={() => restoreDefault()}
			size={"medium"}
		>
			{step === 1 ? (
				<div className={s.modalContainer}>
					<PhoneInput
						placeholder="Номер телефона"
						defaultValue={phone}
						value={(v) => setPhone(trimmedPhoneWithPlus(v))}
					/>
					{validatePhoneText && (
						<div className={s.modal__errorMessage}>{validatePhoneText}</div>
					)}
					<BaseCheckbox
						value={isAgreed}
						onChange={() => setIsAgreed(!isAgreed)}
						inversion
						sizeText={"small"}
					>
						<span>
							Я даю согласие на обработку Персональных данных на основании{" "}
							<a
								href={"/docs/Политика_ООО_МФК_ЦФР_перс_данные.pdf"}
								target="_blank"
								rel="noopener noreferrer"
							>
								Политики конфиденциальности.
							</a>
						</span>
					</BaseCheckbox>
					<div className={s.buttonWrapper}>
						<BaseButton
							disabled={phone.length < 12 || !isAgreed}
							onClick={goToSecondStep}
							text={"Заказать звонок"}
						/>
					</div>
				</div>
			) : (
				<div className={s.modalContainerSuccess}>
					<p>Спасибо за обращение,</p>
					<p>Ваша заявка отправлена!</p>
				</div>
			)}
		</ModalContainer>
	);
});
export default CallbackModal;
