import React, {useImperativeHandle, useState} from 'react'
import s from "./OnlineLoanParameters.module.scss";
import ErrorContainer from "form-submodule/molecules/ErrorContainer";
import useErrorContainer from "hooks/useErrorContainer";
import StepChangeButtons from "form-submodule/molecules/StepChangeButtons";
import elementsStore from "store/elementsStore";
import {errorsParser} from "utils/errorsParser";
import appStore from "store/app";
import {observer} from "mobx-react";
import Calculator from "form-submodule/organisms/Calculator";
import {checkCurrentStep, moveToNextOnlineLoanStep} from "form-submodule/steps/helpers";
import {loanStepsArray} from "constants/onlineLoan";
import {backReqV2} from "helpers";
import getYaId from "utils/getYaId";

const RegistrationParameters = React.forwardRef((props, ref) => {
    // eslint-disable-next-line
    const [isSettingError, setIsSettingError, settingContainerRef] = useErrorContainer()
    const {loanTerm, desiredAmount} = appStore.loanDetail
    const [isLoading, setIsLoading] = useState(false);

    const sendData = async () => {
        try {
            setIsLoading(true)
            await nextStep()
        } catch (e) {
            console.warn(e)
        } finally {
            setIsLoading(false)
        }
    }

    async function nextStep() {
        try {
            if (appStore.isFirstSend) {
                await appStore.setCurrentStep(appStore.currentStep + 1, true)
                await appStore.setLoanDetail({...appStore.loanDetail, currentStep: 'agreements'})
            } else {
                const checkCurrentResponse = await checkCurrentStep(loanStepsArray[0])
                if (checkCurrentResponse?.errors) {
                    elementsStore.showSnackbar(errorsParser(checkCurrentResponse.errors))
                    return false
                }
                const updateSettingsResponse = await updateSettings()
                if (updateSettingsResponse?.errors) {
                    elementsStore.showSnackbar(errorsParser(updateSettingsResponse.errors))
                    return false;
                }
                await moveToNextOnlineLoanStep(loanStepsArray[1])
            }

        } catch (e) {
            elementsStore.showSnackbar(errorsParser(e?.errors))
            return false
        }
    }

    async function updateSettings() {
        const id = appStore?.loanDetail?.id || 123
        if (!id) return {errors: 'no id'}
        try {
            return await backReqV2("params", {
                id,
                desiredAmount,
                loanTerm,
                personalDataAgreement: true,
                // заглушка для обязательного поля. Сейчас скрыто на фронте, но есть на бэке
                loanPurpose: 'Прочие личные нужды',
                yandexId: getYaId()
            })
        } catch (e) {
            return {errors: e.message}
        }
    }

    const validate = () => true
    useImperativeHandle(ref, () => ({ validate, sendData }));
    return <div className={s.settings}>
        <ErrorContainer innerRef={settingContainerRef} hasError={isSettingError}>
            <p className={s.settings__title}>Параметры займа</p>
            <div className={s.settings__group}>
                <Calculator/>
            </div>
        </ErrorContainer>
        <StepChangeButtons loading={isLoading} callback={sendData}/>
    </div>
})
export default observer(RegistrationParameters)
