const API_KEY = process.env.DADATA_API_KEY;

function join(arr, separator = '') {
    return arr.filter(function (n) { return n }).join(separator);
}

function cityToString(address) {
    return join([
        join([address.city_type, address.city], ". "),
        join([address.settlement_type, address.settlement], ". ")
    ])
}

export default async function (query, type) {
    if (!query) {
        return null
    }

    let url = '';
    let parts;

    switch (type) {
        case 'fio':
            url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fio';
            break;
        case 'surname':
            url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fio';
            parts = ['SURNAME']
            break;
        case 'name':
            url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fio';
            parts = ['NAME']
            break;
        case 'patronymic':
            url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fio';
            parts = ['PATRONYMIC']
            break;
        case 'company':
            url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party';
            break;
        case 'email':
            url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/email';
            break;
        case 'carMark':
            url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/car_brand';
            break;
        case 'fmsUnit':
            url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fms_unit';
            break;
        default:
            url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address';
            break;
    }

    const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Token " + API_KEY
        },
        body: JSON.stringify({
            query,
            count: 4,
            ...(parts ? { parts } : {}),
            ...(type === 'addressCompany' ? { "to_bound": { "value": "house" }, } : {})
        })
    })
    const result = await response.json()
    if (Array.isArray(result.suggestions)) {
        switch (type) {
            case 'city':
                return result.suggestions.map(s => ({
                    value: cityToString(s.data)
                })).filter(r => Boolean(r.value));
            default:
                return result.suggestions
        }
    }
    return null
}
