import React, { useState } from "react";
import s from "./StepChangeButtons.module.scss";
import BaseButton from "form-submodule/atoms/BaseButton";
import { observer } from "mobx-react";
import { VI_OFFICE_PHONE, VI_OFFICE_PHONE_URL } from "constants/phones";
import PersonalDataCheck from "./PersonalDataCheck";
import { yandexMetrikaReachGoal } from "utils/yandex-metrika";
import appStore from "store/app";
import elementsStore from "store/elementsStore";
import ModalPortal from "form-submodule/atoms/ModalPortal";

const StepChangeButtons = observer(({
    disabled = false,
    specialDisabled = false,
    callback,
    loading = false,
    isDataOnCheck = false
}) => {

    const [isSpecialModalOpen, setIsSpecialModalOpen] = useState(false)

    async function nextStep() {
        if (specialDisabled) {
            setIsSpecialModalOpen(true)
            return
        }
        try {
            await callback()
            informYandexMetrika()
        } catch (e) {
            console.warn(e)
        }
    }

    const informYandexMetrika = () => {
        if (appStore.currentStep < appStore.backendCurrentStepNumber) return
        const LOCAL_STORAGE_KEY = 'onlineLoanMaxStep'
        const maxStep = Number(localStorage.getItem(LOCAL_STORAGE_KEY)) ?? 0
        if (maxStep >= appStore.currentStep) return;
        try {
            const step = `online_step${appStore.currentStep}`
            yandexMetrikaReachGoal(step)
            localStorage.setItem(LOCAL_STORAGE_KEY, appStore.currentStep.toString())
        }
        catch (e) {
            console.warn(e)
        }
    }

    function previousStep() {
        if (appStore.currentStep <= 0) return
        appStore.setCurrentStep(appStore.currentStep - 1)
    }

    const isMiddleStep = appStore.currentStep > 0 && appStore.currentStep < appStore.stepsArray.length - 1
    const isFinalStep = appStore.currentStep === appStore.stepsArray.length - 1
    const isLoading = loading || elementsStore.isChangeStepButtonLoading

    return (
        <div className={s.StepChangeButtons}>
            <div className={s.StepChangeButtons__phone}>
                <span>Помощь:</span>
                <img src={require(`./img/phone.svg`)} alt={"phone icon"} />
                <a onClick={() => yandexMetrikaReachGoal('online_click_phone_step')} href={VI_OFFICE_PHONE_URL}>{VI_OFFICE_PHONE}</a>
            </div>
            <div
                className={[
                    s.StepChangeButtons__buttons,
                    isMiddleStep ?
                        s["StepChangeButtons__buttons--double"]
                        : s["StepChangeButtons__buttons--once"],
                    isFinalStep ? s["StepChangeButtons__buttons--final"] : ''
                ].join(" ")}
            >
                {(appStore.currentStep !== 0 && !isFinalStep) && (
                    <BaseButton
                        type="link"
                        size="default-change"
                        text="Назад"
                        onClick={previousStep}
                        // arrowLeft={true}
                    />
                )}
                <BaseButton
                    btnId={'nextStepButton'}
                    type={specialDisabled ? 'disabled' : "green"}
                    size="default-change"
                    text={isFinalStep ? "Оформить заявку" : "Далее"}
                    disabled={disabled}
                    onClick={nextStep}
                    loading={isLoading}
                    // arrowRight={!isFinalStep}
                />
                {isDataOnCheck && <PersonalDataCheck />}
            </div>
            <ModalPortal
                showModal={isSpecialModalOpen}
                closeModal={() => setIsSpecialModalOpen(false)}>
                <div className={s.modal}>
                    <p>К сожалению мы не можем оформить Вам онлайн-займ. Приглашаем для оформления <a href={'https://vashinvestor.ru/client/'}>в один из наших офисов</a>. В случае возникновения сложностей, звоните нам по телефону <a href={'tel:88007006331'}>8&nbsp;800&nbsp;700&nbsp;63&nbsp;31</a></p>
                </div>
            </ModalPortal>
        </div>
    );
});

export default StepChangeButtons;
