import React, { useState } from "react";
import { Tooltip } from "antd";

import s from './OnlineLoanSubject.module.scss'
import BaseInput from "form-submodule/atoms/BaseInput";
import SuccessTrimImg from 'img/check.svg'
import CrossImg from 'img/cross.svg'
import TooltipImg from 'img/pts-input-tooltip.png'

const PtsForm = ({ ptsForm, setPtsForm }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    return <div className={s.inputsGroup}>
        <div>
            <Tooltip
                open={showTooltip}
                trigger={'click'}
                placement="topLeft"
                autoAdjustOverflow={false}
                overlayStyle={{
                    maxWidth: 340,
                }}
                overlayInnerStyle={{
                    padding: 24,
                    borderRadius: 16,
                }}
                title={
                    <div className={s.tooltipWrapper}>
                        <div className={s.tooltipBody}>
                            <div className={s.tooltipItem}>
                                <img src={SuccessTrimImg} alt="" />
                                <p>Введите номер ПТС</p>
                            </div>
                            <img className={s.tooltipImg} src={TooltipImg} alt="" />
                            <div className={s.tooltipItem}>
                                <img src={SuccessTrimImg} alt="" />
                                <p><span style={{ textDecoration: 'underline' }}>Или</span> номер ЭПТС</p>
                            </div>
                            <div className={s.tooltipItem}>
                                <img src={CrossImg} alt="" />
                                <p><span style={{ textDecoration: 'underline' }}>НЕ</span> вводите в поле номер СТС</p>
                            </div>
                        </div>
                    </div>
                }
            >
                <BaseInput
                    placeholder="Номер ПТС или ЭПТС *"
                    type={'onlySymbols'}
                    defaultValue={ptsForm.ptsNumber}
                    sessionKey="ptsNumber"
                    maxlength={15}
                    upperCase
                    onFocus={() => setShowTooltip(true)}
                    onBlur={() => setShowTooltip(false)}
                    value={v => setPtsForm({ ...ptsForm, ptsNumber: v })}
                />
            </Tooltip>
        </div>
        {/* <div>
            <BaseInput
                type="date"
                placeholder="Дата выдачи *"
                defaultValue={ptsForm.ptsDate}
                sessionKey="ptsDate"
                value={v => setPtsForm({...ptsForm, ptsDate: v})}
            />
        </div>
        <div>
            <BaseInput
                placeholder="Кем выдан *"
                defaultValue={ptsForm.ptsIssued}
                sessionKey="ptsIssued"
                value={v => setPtsForm({...ptsForm, ptsIssued: v})}
            />
        </div> */}
    </div>
}
export default PtsForm
