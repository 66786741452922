import React from 'react'
import s from 'form-submodule/atoms/BaseInput/BaseInput.module.scss'
import InputMask from "react-input-mask"

class DateInput extends React.Component {
    constructor(props) {
        super();
        this.props = props
        this.initialType = this.props.type
        this.state = {
            inputField: '',
        };
    }

    componentDidMount() {
        if (this.props.defaultValue) {
            this.setState({
                inputField: this.isoStringToView(this.props.defaultValue)
            })
        }
        this.props.value(this.state.inputField)
    }
    componentDidUpdate(prevProps) {
        if (this.props.defaultValue && this.props.defaultValue !== prevProps.defaultValue) {
            this.setState({
                inputField: this.isoStringToView(this.props.defaultValue)
            })
        }
    }
    viewStringToIso(str) {
        return str.split('.').reverse().join('-')
    }
    isoStringToView(str) {
        return str.split('-').reverse().join('.')
    }
    validateAndSave(value) {
        if (!value.includes('_')) {
            let [DD, MM, YYYY] = value.split('.');
            const now = new Date();

            if (+DD > 31) {
                DD = 31
            }
            if (+MM > 12) {
                MM = 12
            }
            if (+YYYY < 1950) {
                YYYY = 1950
            }
            const curYear = now.getFullYear();
            if (+YYYY > curYear) {
                YYYY = curYear
            }

            console.log([DD, MM, YYYY])

            const [isoString] = new Date(`${YYYY}-${MM}-${DD}`).toISOString().split('T')

            this.setState({
                inputField: this.isoStringToView(isoString)
            })

            this.props.value(isoString)

            if (this.props.sessionKey) {
                sessionStorage.setItem(this.props.sessionKey, isoString)
            }
        }
    }

    handleChange(value) {
        this.setState({
            inputField: value
        })

        this.validateAndSave(value)
    }
    onFocus(e) {
        if (this.props.onFocus) {
            this.props.onFocus(e)
        }
    }
    onBlur(e) {
        this.validateAndSave(e.target.value)

        if (this.props.onBlur) {
            this.props.onBlur(e)
        }
    }
    render() {
        const classes = [s.inputWrapper]
        if (this.state.inputField) classes.push(s.activePlaceholder)
        return (
            <div className={classes.join(' ')} style={this.props.style}>
                <div className={s.placeholder}>{this.props.placeholder}</div>
                <InputMask
                    mask={this.props.mask ? this.props.mask : "99.99.9999"}
                    onChange={(e) => this.handleChange(e.target.value)}
                    style={{ height: this.props.height, fontSize: this.props.fontSize }}
                    className={s.input}
                    value={this.state.inputField}
                    onFocus={(e) => this.onFocus(e)}
                    onBlur={(e) => this.onBlur(e)}
                    autoComplete="off"
                    type="text"
                    inputMode="numeric"
                    disabled={this.props.disabled ?? false}
                />
            </div>
        )
    }
}

export default DateInput
