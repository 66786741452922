import {
    action,
    computed,
    observable,
    // runInAction
} from 'mobx'
import { backReq, backReqV2 } from 'helpers'
import { loanStepsArray as steps, DEFAULT_LOAN } from "constants/onlineLoan";

// let $id = 0
// const buildRandomNotification = () => ({
//     id: $id++,
//     type: ['success', 'info', 'warning', 'error'][Math.floor(Math.random() * 4)],
//     date: new Date(),
//     text: shuffle((new Array(3).fill('lorem ipsum dolor sit amet lorem ipsum dolor sit amet').join(' ')).split(' ')).join(' '),
//     read: false,
// })
const baseLoanDetail = {
    id: null,
    desiredAmount: DEFAULT_LOAN,
    loanTerm: 4,
    loanPurpose: 'Прочие личные нужды',
    currentStep: 1,
    stepsDone: ['params'],
}
class AppModel {
    // Сессия
    @observable token = localStorage.getItem('sessionToken')

    @action setToken = ({ sessionToken }) => {
        localStorage.setItem('sessionToken', sessionToken)
        this.token = sessionToken
    }
    @action clearToken = () => {
        localStorage.removeItem('sessionToken')
        this.token = null
    }
    @action updateSession = (token) => this.setToken(token)
    @action outdateSession = () => this.clearToken()

    // Модалка Заказать звонок
    @observable isCallbackModalVisible = false
    @action setIsCallbackModalVisible = bool => this.isCallbackModalVisible = bool

    // Модалка займа
    @observable isLoanModalVisible = false
    @action setIsLoanModalVisible = bool => this.isLoanModalVisible = bool

    // Займ
    @observable loanRemoteFieldValues = {}
    @action getLoanRemoteFieldValues = async () => {
        if (Object.keys(this.loanRemoteFieldValues).length) return
        try {
            const response = await backReq('field_values')
            if (response.errors) {
                new Error('field_values error')
            }
            this.loanRemoteFieldValues = response.data
        } catch (e) {
            console.log('field_values error')
            this.loanRemoteFieldValues = {}
        }
    }

    @observable loanDetail = baseLoanDetail
    @observable loanDetailLoading = false

    @computed get isOfferExist() {
        return !!this.loanDetail?.id
    }
    @computed get isFirstSend() {
        return Object.keys(this.loanDetail).length <= 6
    }

    @action setLoanDetail = (loan) => {
        if (!loan) return
        if (loan.id) {
            localStorage.setItem('loanId', loan.id)
        } else {
            sessionStorage.setItem('detail', JSON.stringify(loan))
        }
        this.loanDetail = loan
    }
    @action setDefaultLoanDetail = () => {
        this.loanDetail = JSON.parse(JSON.stringify(baseLoanDetail))
    }

    @action refreshLoanDetail = async (id) => {
        if (!this.loanDetail.id && !id) return
        try {
            this.setLoanDetailLoading(true);
            const response = await backReqV2('detail', { id: +(id ?? this.loanDetail.id) })
            if (response.errors) {
                localStorage.removeItem('loanId')
                localStorage.removeItem('sessionToken')
                this.setDefaultLoanDetail()
                this.setIsLoanModalVisible(false)
                await this.setCurrentStep(1, true)
                return
            }
            this.setLoanDetail(response.data)
            localStorage.setItem('loanId', response.data.id)
            this.setLoanDetailLoading(false);
            return response.data
        } catch (e) {
            throw e
        }
    }

    @action setLoanDetailLoading = (payload) => {
        this.loanDetailLoading = payload;
    }

    // шаги
    @observable currentStep = 1
    @observable stepsArray = steps

    @computed get backendCurrentStepNumber() {
        const current = this.loanDetail?.currentStep
        if (!current) return 0
        if (current === 'summary') return 4
        let currentStepNumber = this.stepsArray.findIndex(step => step === current)
        return currentStepNumber === -1 ? 0 : currentStepNumber
    }

    @action setCurrentStep = async (value, isChecked = false) => {
        // init value
        if (this.currentStep === undefined || isChecked) {
            this.currentStep = value
            return
        }
        // step back
        if (value < this.currentStep) {
            this.currentStep = value
            return
        }
        //  avoid error
        if (!this.loanDetail?.currentStep) return;
        // if walking through the previous steps
        let backCurrentStepNumber = this.stepsArray.findIndex((step) => step === this.loanDetail?.currentStep)
        if (this.loanDetail?.currentStep === 'summary') backCurrentStepNumber = 4
        // if (this.isCheckLimit) backCurrentStepNumber = 1
        if (value <= backCurrentStepNumber || this.isFirstSend) {
            this.currentStep = value
        }
        // else {
        // ask permission
        // const id = this.loanDetail?.id
        // const response = await backReq('change_step', {
        //     "step": this.stepsArray[value],
        //     id
        // })
        // if (response.code === 200) {
        //     runInAction(() => this.currentStep = value)
        // } else {
        //     const err = new Error('step error')
        //     err.responseData = response.errors
        //     throw err
        // }
        // }
    }

    // idx проверка лица на живость
    @observable isLivelinessCheckInProgress = null
    @observable livelinessCheck = null
    @observable passportCheck = null

    @action setLivelinessCheck = (val) => {
        /* val =
             "real" - Живость подтверждена
             "attack" - Живость не подтверждена
             "unfinished" - Проверка не завершена
        */
        this.livelinessCheck = val
    }
    @action setIsLivelinessCheckInProgress = (bool) => (this.isLivelinessCheckInProgress = bool)
    @action setPassportCheck = (bool) => (this.passportCheck = bool)

}

// Создаем и экспортируем стор

const appStore = new AppModel()

export default appStore
