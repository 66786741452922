export const MIN_LOAN = 30_000;
export const DEFAULT_LOAN = 300_000;
export const MAX_LOAN = 1_000_000;
export const FIRST_LOAN_PERCENT = 0.075;
export const REPEATER_LOAN_PERCENT = 0.07;

export const repeaterStepsArray = [
	"params",
	"personal_confirm",
	"personal_data",
	"loan_subject",
	"card",
	"summary",
];
export const onlineLoanStepsArray = [
	"params",
	"client_data",
	"personal_confirm",
	"personal_data",
	"loan_subject",
	"card",
	"summary",
];
export const loanStepsArray = [
	"params",
	"client_data",
	"personal_confirm",
	"personal_data",
	"loan_subject",
];

const IN_PROCESS = "in_process";
const ON_CHECK = "on_check";
const APPROVED = "approved";
const IMPOSSIBLE = "impossible";
const NEED_INFO = "need_info";
const DECREASE_AMOUNT = "decrease_amount";
const IN_OFFICE = "in_office";
const SIGNED = "signed";
const MISTAKE = "mistake";
const COMPLETE = "complete";

export const loanStatuses = {
	inProcess: IN_PROCESS,
	onCheck: ON_CHECK,
	approved: APPROVED,
	impossible: IMPOSSIBLE,
	needInfo: NEED_INFO,
	decreaseAmount: DECREASE_AMOUNT,
	inOffice: IN_OFFICE,
	signed: SIGNED,
	mistake: MISTAKE,
	complete: COMPLETE,
};
export const loanStatusesForUser = {
	inProcess: "В процессе",
	onCheck: "На проверке",
	approved: "Одобрено",
	impossible: "Невозможно оформление займа онлайн",
	needInfo: "Необходима дополнительная информация",
	decreaseAmount: "Условное одобрение с понижением суммы займа",
	inOffice: "Оформление займа в офисе",
	signed: "Подписана",
	mistake: "Ошибка при переводе",
	complete: "Займ предоставлен",
};
export const loanStatusesArray = [
	IN_PROCESS,
	ON_CHECK,
	APPROVED,
	IMPOSSIBLE,
	NEED_INFO,
	DECREASE_AMOUNT,
	SIGNED,
	IN_OFFICE,
	MISTAKE,
	COMPLETE,
];

export const BACKEND_CHECKING_CLIENT_DATA_ERROR = "Идет проверка данных 2 шага";
export const BACKEND_CHECKING_LIMIT_ERROR =
	"Данные обновлялись больше 3 раз за 24ч";
