import {sendPhotoToDatabase} from "helpers";

async function loadImageSrc(url) {
    if (!url) return
    return await fetch(url)
        .then(_createImageSrcFromResponse)
}

const _createImageSrcFromResponse = async (res) => {
    let blob = await res.blob()
    return URL.createObjectURL(blob);
}

const uploadOnlineLoanPhoto = async (type, file) => {
    const formData = new FormData();
    formData.append("photo_type", type);
    formData.append("file",file);
    let blobURL = ""
    const response = await sendPhotoToDatabase(formData)
    if (response.errors){
        const error = new Error()
        error.data = response.errors
        throw error
    }

    blobURL = await loadImageSrc(response.data.url)
    return {blobURL, id: response.data.id}
}

export {
    uploadOnlineLoanPhoto,
    loadImageSrc
}
