export default function (state) {
    return Object.keys(state).reduce((acc, key) => {
        const value = sessionStorage.getItem(key)
        if (value) {
            let parsed
            try {
                parsed = JSON.parse(value)
            } catch {}
            acc[key] = typeof parsed === 'object' ? parsed : value
        }
        return acc
    }, {})
}
