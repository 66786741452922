import s from "./CalculatorSection.module.scss";
import React from "react";
import Tag1 from "img/calculator-tag-1.svg";
// import Tag2 from "img/calculator-tag-2.svg";
import Tag3 from "img/calculator-tag-3.svg";
import Tag4 from "img/calculator-tag-4.svg";
import Tag5 from "img/calculator-tag-5.svg";

const tags = [
    { src: Tag1, label: 'Документы и авто остаются у вас' },
    // { src: Tag2, label: 'Находимся в реестре ЦБ РФ', href: 'https://vashinvestor.ru/media/legal/%D0%A1%D0%B2%D0%B8%D0%B4%D0%B5%D1%82%D0%B5%D0%BB%D1%8C%D1%81%D1%82%D0%B2%D0%BE_%D0%A6%D0%91_%D0%9C%D0%9A%D0%9A_%D0%A6%D0%A4%D0%A0_%D0%92%D0%98_1_1_f8ujHP5.pdf' },
    { src: Tag3, label: 'Одобряем 95% заявок', },
    { src: Tag4, label: 'С любой кредитной историей' },
    { src: Tag5, label: 'Возможно досрочное закрытие' },
]
const Tags = () => {
    return <div className={s.tagsWrapper}>
        {tags.map(tag => <div className={s.tag} key={tag.label}>
            <img src={tag.src} alt={tag.label} />
            {tag.hasOwnProperty('href') ? (<a href={tag.href}> {tag.label} </a>) : (<span>{tag.label}</span>)}
        </div>)}
    </div>
}
export default Tags
