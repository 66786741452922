import React from 'react';
import ReactDOM from "react-dom";
import ModalContainer from "form-submodule/atoms/ModalContainer";

const ModalPortal = ({closeModal, showModal, size, title, btnBack=false, onClickBack, children}) => {
  return ReactDOM.createPortal(
    <ModalContainer
      title={title}
      showModal={showModal}
      closeModal={closeModal}
      size={size}
      btnBack={btnBack}
      onClickBack={onClickBack}
    >
      {children}
    </ModalContainer>,
    document.getElementById('root-modal'))
}
export default ModalPortal
